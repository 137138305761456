<template>
    <div>
        <b-sidebar
            id="sidebar-note"
            aria-labelledby="sidebar-no-header-title"
            no-header
            bg-variant="white"
            shadow
            backdrop
            right
            width="400px"
            v-show="sidebarNoteIsActive"
            >
            <template #default="{ hide }">
                <!-- Header -->
                <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                >
                <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
                    Nouvelle note
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
                </div>
                <validation-observer ref="NoteValidations">
                <b-form class="p-2" @submit.prevent="addNote" autocomplete="off" ref="addNoteForm">
                    <b-form-group label="Note" label-for="note">
                    <validation-provider
                        #default="{ errors }"
                        name="Type"
                        rules="required"
                        >
                        <b-form-textarea
                        v-model="note.texte"
                        trim
                        :state="errors.length > 0 ? false : null"
                        placeholder="écrivez une note"
                        rows="5"
                        no-resize
                        id="note"
                        />
                    </validation-provider>
                    </b-form-group>


                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        type="submit"
                    >
                        Enregistrer
                    </b-button>
                    </div>
                </b-form>
                </validation-observer>
            </template>
            </b-sidebar>
        <b-row>
            <b-col>
                <b-card class="bg-light-primary">
                    <p class="mb-0">
                        Article 8 RGPD : il est interdit de collecter ou traiter des données à caractère personnel qui font apparaitre, directement ou indirectement des origines raciales ou ethnique, les opinions politiques, philosophiques ou religieuses ou l'appartenance syndicale des personnes ou qui sont relatives à la santé ou la vie sexuelle de celles-ci.
                    </p>
                </b-card>
            </b-col>
        </b-row>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-1 mt-1">
              <b-button 
                variant="primary"
                size="md"
                class="ml-1"
                v-b-toggle.sidebar-note
                @click="clickAddNote"
                >
                <feather-icon
                    icon="PlusIcon"   
                />
                <span class="align-middle text-uppercase">  Ajouter une Note</span></b-button>
          </div>
        </div>
        <b-list-group class="notes_list">
                    <template v-if="notes.length > 0">
                        <b-list-group-item v-for="(note,index) in notes" :key="index">
                            <!-- <feather-icon
                                icon="EditIcon"
                                size="26"
                                class="cursor-pointer text-primary"
                                @click="editNote(index)"
                                v-b-toggle.sidebar-note
                            /> -->
                            <h6 class="font-weight-bolder">{{note.created_by.personne_physique.nom}} {{note.created_by.personne_physique.prenom}}</h6>
                            <p>{{note.texte}}</p>
                            <small class="font-weight-bolder">{{getDayMonth(note.created_at)}}</small>
                        </b-list-group-item>
                    </template>
                    <template v-else>
                        <b-list-group-item class="text-center pt-3 pb-3">
                            <h6 class="font-weight-bolder">Aucune note disponible</h6>
                        </b-list-group-item>
                    </template>
                    <!-- <b-list-group-item>
                        <h6 class="font-weight-bolder">Nom et prenom</h6>
                        <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression</p>
                        <small class="font-weight-bolder">JJ/MM</small>
                    </b-list-group-item>
                    <b-list-group-item>
                        <h6 class="font-weight-bolder">Nom et prenom</h6>
                        <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression</p>
                        <small class="font-weight-bolder">JJ/MM</small>
                    </b-list-group-item>
                    <b-list-group-item>
                        <h6 class="font-weight-bolder">Nom et prenom</h6>
                        <p>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression</p>
                        <small class="font-weight-bolder">JJ/MM</small>
                    </b-list-group-item> -->
        </b-list-group>
    </div>
</template>
<script>
import moment from 'moment';
import { BRow, BCol,BButton,BListGroup,BListGroupItem,BForm,BFormGroup,BFormInput,BSidebar,VBToggle,BFormTextarea,BCard } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BRow, 
        BCol,
        BButton,
        BListGroup,
        BListGroupItem,
        BForm,
        BFormGroup,
        BFormInput,
        BSidebar,
        VBToggle,
        Ripple,
        ValidationProvider,
        ValidationObserver,
        BFormTextarea,
        ToastificationContent,
        BCard
    },
    props:['prospectNote'],
    data() {
        return {
            operationType:'add',
            sidebarNoteIsActive:false,
            prospectNoteLocal: null,
            notes:[],
            note:{
                id:null,
                noteable_type:'PERSONNE_PHYSIQUE',
                interface_type: "note",
                texte:null,
                noteable_id:null
            }
        }
    },
    directives: {
    "b-toggle": VBToggle,
    Ripple

    },
    watch:{
        prospectNote: {
          deep: true,
          handler(val){
              this.prospectNoteLocal = val
              this.note.noteable_id = this.prospectNoteLocal.enum.prospect.id
              this.notes = this.prospectNoteLocal.enum.getprospectpersonephysique.notes
          }
        }
    },
    methods:
    {
        getDayMonth(date)
        {
            return moment(date).format("DD/MM H:m")
        },
        clickAddNote()
        {
            this.sidebarNoteIsActive = true;
            this.note.id = null;
            this.note.texte = null
            this.operationType = 'add'
        },
        addNote(){
            let titleMsg = '';
            this.$refs.NoteValidations.validate().then(success => {
                    if (success) {
                        this.$http.post(`note`,this.note).then((r) => {
                            console.log(r);
                            this.sidebarNoteIsActive = false
                            if(this.operationType == 'add')
                            {
                                this.notes.unshift(r.data);
                                titleMsg = `La note a été ajouté`;
                            }
                            // else
                            // {
                            //     console.log(this.operationType)
                            //     for(let i= 0; i< this.notes.length;i++)
                            //     {
                            //         console.log(this.notes[i],this.note.id)
                            //         if(this.notes[i].id == this.note.id)
                            //         {
                            //             this.notes[i] = r.data;
                            //             console.log(this.notes[i],this.note.id)
                            //             break;
                            //         }
                            //     }
                            //     titleMsg = `La note a éte modifier`;
                            // }

                            
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: titleMsg,
                                icon: 'UserIcon',
                                variant: 'success',
                                },
                            })
                        this.$refs.addNoteForm.reset();
                        }).catch((err)=>{
                            console.log(err)
                        })
                    }
                    else
                    {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: `Veuillez remplir les champs obligatoires`,
                                icon: 'UserIcon',
                                variant: 'danger',
                                },
                            })
                    }
                });
        },
        // editNote(index)
        // {
        //     this.sidebarNoteIsActive = true
        //     this.operationType = 'update'
        //     let noteData = this.notes[index];
        //     this.note.id = noteData.id;
        //     this.note.texte = noteData.texte;
        //     // console.log(noteData,this.note.texte);
        // }
    }
}
</script>
<style scoped>
.notes_list
{
    margin-bottom: 1rem;
}
.notes_list.list-group .list-group-item:nth-child(even) {
    background-color: #F4F7FC;
}
.notes_list svg
{
    float: right;
}
</style>