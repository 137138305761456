var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.can('Import contract') || _vm.can('Update contract'))?_c('b-sidebar',{ref:"sideBarContrat",attrs:{"id":"sidebar-contrat","backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[(_vm.isUpdate)?_c('span',[_vm._v(" Modifier le contrat "+_vm._s(_vm.composeData.n_contrat)+" ")]):_c('span',[_vm._v("Nouveau contrat")])]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"contratComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),_c('h4',[_vm._v("INFORMATIONS")]),_c('validation-provider',{attrs:{"name":"client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Client*","label-for":"client"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"getOptionKey":function (client) { return client.id + client.type; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.clientsOptions,"input-id":"client","label":"nom","placeholder":"Client"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
var categorie = ref.categorie;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))]),_c('b-badge',{staticClass:"mr-2 float-right",attrs:{"variant":_vm.categorieVariant(categorie)}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(categorie))+" ")])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.client),callback:function ($$v) {_vm.$set(_vm.composeData, "client", $$v)},expression:"composeData.client"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune client disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,true)}),(_vm.composeData.client && _vm.composeData.client.statutFiche === 'Prospect')?_c('b-form-group',{attrs:{"label":"Souhaitez vous changer le statut du prospect en client ?"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.optionsChangedToProspect,"name":"radio-inline"},model:{value:(_vm.composeData.isChangedToProspect),callback:function ($$v) {_vm.$set(_vm.composeData, "isChangedToProspect", $$v)},expression:"composeData.isChangedToProspect"}})],1):_vm._e(),_c('h4',[_vm._v("CONTRAT")]),_c('b-form-group',{attrs:{"label":"N° contrat*","label-for":"num_contrat"}},[_c('validation-provider',{attrs:{"name":"n° contrat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"num_contrat","disabled":_vm.isUpdate,"state":errors.length > 0 ? false : null,"placeholder":"N° contrat"},model:{value:(_vm.composeData.n_contrat),callback:function ($$v) {_vm.$set(_vm.composeData, "n_contrat", $$v)},expression:"composeData.n_contrat"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Risque*","label-for":"Risque"}},[_c('validation-provider',{attrs:{"name":"risque","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.risquesOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.risque_id),callback:function ($$v) {_vm.$set(_vm.composeData, "risque_id", $$v)},expression:"composeData.risque_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Assureur*","label-for":"assureur"}},[_c('validation-provider',{attrs:{"name":"assureur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assureursOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.assureur_id),callback:function ($$v) {_vm.$set(_vm.composeData, "assureur_id", $$v)},expression:"composeData.assureur_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Date d'effet*","label-for":"date-effet"}},[_c('validation-provider',{attrs:{"name":"date d'effet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-effet","config":_vm.configDate,"placeholder":"Date d'effet"},model:{value:(_vm.composeData.date_effet),callback:function ($$v) {_vm.$set(_vm.composeData, "date_effet", $$v)},expression:"composeData.date_effet"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Echéance anniversaire","label-for":"echeance-anniversaire"}},[_c('validation-provider',{attrs:{"name":"échéance d'anniversaire","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"echeance-anniversaire","config":_vm.configDate,"placeholder":"Echéance d'anniversaire"},model:{value:(_vm.composeData.echeance_anniversaire),callback:function ($$v) {_vm.$set(_vm.composeData, "echeance_anniversaire", $$v)},expression:"composeData.echeance_anniversaire"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Contrat concurrence","label-for":"contrat-concurrence"}},[_c('validation-provider',{attrs:{"name":"Contrat concurrence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing",attrs:{"id":"contrat-concurrence"}},[_c('b-form-radio',{attrs:{"name":"concurrence-radios","value":"1"},model:{value:(_vm.composeData.concurrence),callback:function ($$v) {_vm.$set(_vm.composeData, "concurrence", $$v)},expression:"composeData.concurrence"}},[_vm._v(" oui ")]),_c('b-form-radio',{attrs:{"name":"concurrence-radios","value":"0"},model:{value:(_vm.composeData.concurrence),callback:function ($$v) {_vm.$set(_vm.composeData, "concurrence", $$v)},expression:"composeData.concurrence"}},[_vm._v(" non ")])],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type de paiement","label-for":"typeFractionnement"}},[_c('b-form-select',{attrs:{"options":_vm.typeFractionnementOptions},model:{value:(_vm.composeData.typeFractionnement),callback:function ($$v) {_vm.$set(_vm.composeData, "typeFractionnement", $$v)},expression:"composeData.typeFractionnement"}})],1),_c('b-form-group',{attrs:{"label":"Statut*","label-for":"statut"}},[_c('validation-provider',{attrs:{"name":"statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statutOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.statut),callback:function ($$v) {_vm.$set(_vm.composeData, "statut", $$v)},expression:"composeData.statut"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),(_vm.isRésilié || _vm.isTemporaire)?_c('b-form-group',{attrs:{"label":"Date de fin","label-for":"date-fin"}},[_c('validation-provider',{attrs:{"name":"date de fin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-fin","config":_vm.configDate,"placeholder":"Date de fin"},model:{value:(_vm.composeData.date_fin),callback:function ($$v) {_vm.$set(_vm.composeData, "date_fin", $$v)},expression:"composeData.date_fin"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Modalité de paiement","label-for":"Modalite_paiement"}},[_c('b-form-select',{attrs:{"options":_vm.modalitePaiementOptions},model:{value:(_vm.composeData.modalite_paiement),callback:function ($$v) {_vm.$set(_vm.composeData, "modalite_paiement", $$v)},expression:"composeData.modalite_paiement"}})],1),_c('b-form-group',{attrs:{"label":"Prime TTC","label-for":"budget"}},[_c('validation-provider',{attrs:{"name":"Prime TTC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"budget","options":_vm.options.number,"raw":false,"state":errors.length > 0 ? false : null,"placeholder":"10 000,00"},model:{value:(_vm.composeData.budget),callback:function ($$v) {_vm.$set(_vm.composeData, "budget", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.budget"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"taux de commission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Taux de commission","label-for":"taux_commission"}},[_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"10,00","type":"number"},model:{value:(_vm.composeData.taux_commission),callback:function ($$v) {_vm.$set(_vm.composeData, "taux_commission", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"composeData.taux_commission"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('h4',[_vm._v("RATTACHEMENT")]),_c('b-form-group',{attrs:{"label":"Courtier","label-for":"courtier"}},[_c('b-form-input',{attrs:{"id":"courtier","disabled":"","placeholder":"Courtier"},model:{value:(_vm.currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial),callback:function ($$v) {_vm.$set(_vm.currentUser.courtier_user[0].courtier.personne_morale, "denomination_commercial", $$v)},expression:"currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial"}})],1),_c('validation-provider',{attrs:{"name":"commercial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Commercial*","label-for":"commercial"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.commercialOptions,"input-id":"commercial","label":"nom","placeholder":"Commercial"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.commercial),callback:function ($$v) {_vm.$set(_vm.composeData, "commercial", $$v)},expression:"composeData.commercial"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune commercial disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordContrat}},[_vm._v(" Enregistrers ")])],1)],1)],1)]}}],null,false,4117542450)}):_vm._e(),_c('b-sidebar',{ref:"sideBarImportContrat",attrs:{"id":"sidebar-importNewContrat","bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v("Importer un document")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"validateImportContratRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Nature de document","label-for":"h-famille"}},[_c('validation-provider',{attrs:{"name":"nature de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-famille-autre-document","options":_vm.optionsFamille,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.changeListoptionType()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.famille),callback:function ($$v) {_vm.famille=$$v},expression:"famille"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type de document","label-for":"h-type"}},[_c('validation-provider',{attrs:{"name":"type de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-type-autre-document","options":_vm.optionsType,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.composeData.idType),callback:function ($$v) {_vm.$set(_vm.composeData, "idType", $$v)},expression:"composeData.idType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Parcourir le fichier'),expression:"'Parcourir le fichier'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openToSelectFile}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"fileautredocumentProspet"},model:{value:(_vm.composeData.file),callback:function ($$v) {_vm.$set(_vm.composeData, "file", $$v)},expression:"composeData.file"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choisissez un document","value":_vm.composeData.file ? _vm.composeData.file.name : '',"state":errors.length > 0 ? false : null}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordDocument}},[_vm._v(" Enregistrer ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }