<template>
  <div>
    <div class="d-flex flex-row bd-highlight mb-2">
      <!-- <div class="p-0 bd-highlight">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-1 mt-1"
            size="md"
            variant="primary"
            @click="$router.push({ name: 'projets-proposition' })">
          <feather-icon icon="PlusIcon"/>
          <span class="align-middle text-uppercase">Ajouter un projet</span></b-button>
      </div> -->
      <div class="p-0 bd-highlight">
         <import-new-doc-projet
         :courtier-id="currentUser.courtier_user[0].courtier_id"
          :user-id="currentUser.courtier_user[0].user_id"
          :etude-id="etude_id_joindre"
          :devis-id="devis_id_joindre"
          :client-id="client_id_joindre"
          :client-type="client_type_joindre"
      />
        <import-new-projet v-if="prospectDevis"
            :courtier-id="currentUser.courtier_user[0].courtier_id"
            :user-id="currentUser.courtier_user[0].user_id"
            :commercial-id="prospectDevis.enum.getprospectpersonephysique.distributeur.user_id"
            :prospect-data-from-fiche-tier="$_.get(prospectDevis,'enum.prospect', null)"
            :assureurs-options="assureursOptions"
            :risques-options="risquesOptions"
            :projet-for-update="projetForUpdate"
            :prospect-data="[]"
            :is-form-fiche-tier="true"
            @record-projet-externe-returned="RecordProjetExterneReturned"
            @clear-data-projet-update="clearDataProjetUpdate"
        /></div>
    </div>

    <b-card>
      <!-- table -->
      <vue-good-table
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
          @on-selected-rows-change="selectionChanged"
          :rows="rows"
          :rtl="direction"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
          :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
          theme=""
      >
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'client'" class="text-nowrap">
            <span>
              {{ $_.get(prospectDevisLocal.enum, 'prospect.nom', null) }}
              {{ $_.get(prospectDevisLocal.enum, 'prospect.prenom', null) }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span
              v-if="props.column.field === 'num_projet'"
              class="text-nowrap"
          >
            <span>
              {{
                props.row.etude.source === 'Interne' ? props.row.devis_no_interne : props.row.devis_no_externe
              }}
            </span>
          </span>

          <span
              v-if="props.column.field === 'tarification.produit'"
              class="text-nowrap"
          >
            <span>
              {{
                $_.get(prospectDevisLocal.enum, 'tarification', 'non preconise')
              }}
            </span>
          </span>

          <span v-if="props.column.field === 'devis_avancement'"
            class="text-nowrap">
            <span>
              {{
                props.row.devis_etape_avancement ? props.row.devis_etape_avancement : '--'
              }}
            </span>

          </span>

          <span v-if="props.column.field === 'commercial'" class="text-nowrap">
            <span
            >{{ props.row.user.personne_physique.prenom + ' ' + props.row.user.personne_physique.nom }}</span
            >
          </span>

          <span
              v-if="props.column.field === 'date_creation'"
              class="text-nowrap"
          >
            <span>{{
                $_.get(prospectDevisLocal.enum, 'devis.date_creation', null)
              }}</span>
          </span>
          <div v-if="props.column.field == 'source'" class="text-uppercase">
            <b-badge :variant="sourceVariant(props.row.etude.source)">
              {{ props.row.etude.source }}
            </b-badge>
          </div>

          <span v-if="props.column.field === 'date_effet'" class="text-nowrap">
            <span>
              {{ $_.get(prospectDevisLocal.enum, 'devis.date_effet', null) }}
            </span>
          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="16"
                  />
                </template>
                <b-dropdown-item @click="redirectprojet(props.row)"  v-if="props.row.devis_avancement == 'TARIFICATION' && props.row.etude.source === 'Interne'">
                  <feather-icon class="mr-50" icon="CheckCircleIcon"/>
                  <span>Finaliser</span>
                </b-dropdown-item>
                <b-dropdown-item 
                 v-b-toggle.sidebar-2  v-if="props.row.etude.source === 'Externe'"
                  @click="updateProjectExterne(props.row)" 
                  
                  >
                  <feather-icon class="mr-50" icon="CheckCircleIcon"/>
                  <span>Modifier</span>
                </b-dropdown-item>
                  <b-dropdown-item v-b-toggle.sidebar-3 @click="joindreFile(props.row.etude_id, props.row.id, props.row.devisable_id, props.row.devisable.type)">
                  <feather-icon
                      class="mr-50"
                      icon="LinkIcon"
                  />
                  <span>Joindre</span>
                </b-dropdown-item>
                <b-dropdown-item  @click="JoindreContrat(props.row.id, props.row.etude.source)">
                  <feather-icon
                      class="mr-50"
                      icon="SearchIcon"
                  />
                  <span >Visualiser</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDevis(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item>
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editer</span>
              </b-dropdown-item> -->
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
          
          <b-row class="mt-2 align-items-center">
            <b-col md="4">
              <b-input-group>
                  <b-form-select
                      v-model="selected_action"
                      :options="options"
                  />
                  <b-input-group-append>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="action_masse(selected_action)"
                    >
                      <span>Valider </span>
                      <feather-icon
                          class="mr-50"
                          icon="ArrowRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>

      </vue-good-table>
    </b-card>
    <b-modal centered size="lg" id="modal-display-document" ref="modal-display-document" :hide-footer="hideFooter">
             <autres-document  :devis-id-doc="joindre_id_contrat" :devis-type-doc="joindre_type_contrat"/>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BSidebar,
  VBToggle,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ImportNewProjet from '../../projets/ImportNewProjet.vue'
import ImportNewDocProjet from '@/views/projets/ImportNewDocProjet'
import AutresDocument from '@/views/projets/AutresDocument'

export default {
  components: {
    ImportNewDocProjet,
    AutresDocument,
    Ripple,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BBadge,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSidebar,
    VBToggle,
    // UI
    ImportNewProjet,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: ['prospectDevis'],
  data() {
    return {
      risquesOptions: [],
      assureursOptions: [],
      hideFooter :false,
      etude_id_joindre:null,
      devis_id_joindre:null,
      client_id_joindre:null,
      client_type_joindre:null,
      joindre_id_contrat: null,
      joindre_type_contrat: null,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      projets: [],
      pageLength: 5,
      dir: false,
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'Supprimer',
          text: 'Supprimer'
        },
      ],
      selected_action: null,
      columns: [
        {
          label: 'N° projet',
          field: 'num_projet',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Nom Projet',
        //   field: 'etude.etude_nom',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },

        {
          label: 'Risque',
          field: 'risque.label',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Assureur',
          field: 'assureur.nom_com_assureur',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Avancement',
        //   field: 'devis_avancement',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        {
          label: 'Statut',
          field: 'statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Source",
          field: "source",
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'effet',
          field: 'date_effet',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Budget',
          field: 'prime',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Commercial',
          field: 'commercial',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
      ],
      rows: [],
      clients: null,
      searchTerm: '',
      prospectDevisLocal: [],
      options_masse: [],
      projetForUpdate: {
        idEtude: null,
        idDevis: null,
        client: null,
        risque: null,
        assureur: null,
        statut: null,
        num_devis: null,
        date_effet: null,
        date_creation: null,
        budget_propose: null,
        budget_actuel: null,
        variation: null,
        courtier_id: null,
        user_id: null
      },
      selectedrows_masse : []
    }
  },
  created() {
    this.fetchRisqueList()
    this.fetchAssureurList()
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    prospectDevis: {
      deep: true,
      handler(val) {
        this.prospectDevisLocal = val
        this.rows =
            this.prospectDevisLocal.enum.getprospectpersonephysique.devis
      },
    },
  },
  mounted() {

    this.hideFooter = true
  },
  methods: {
    joindreFile(etude,devis,clientId, clientType){
      this.etude_id_joindre = etude
      this.devis_id_joindre = devis
      this.client_id_joindre = clientId
      this.client_type_joindre = clientType
    },
    JoindreContrat(devisId, devisType){
        this.joindre_id_contrat=devisId
        this.joindre_type_contrat=devisType
        if (this.joindre_id_contrat && this.joindre_type_contrat) {
          this.$refs['modal-display-document'].show()
       }
       },
    sourceVariant(value) {
      const statusColor = {
        Externe: 'light-primary',
        Interne: 'light-warning',
      }
      return  statusColor[value]
    },
    updateProjectExterne(data) {
      this.projetForUpdate.idEtude = data.etude.id
      this.projetForUpdate.idDevis = data.id
      this.projetForUpdate.client = data.devisable
      this.projetForUpdate.risque = data.risque_id
      this.projetForUpdate.assureur = data.assureur_id
      this.projetForUpdate.statut = data.statut
      this.projetForUpdate.num_devis = data.devis_no_externe
      this.projetForUpdate.date_effet = this.formattingDateSql(data.date_effet)
      this.projetForUpdate.date_creation = this.formattingDateSql(data.date_creation)
      this.projetForUpdate.budget_propose = data.prime
      this.projetForUpdate.budget_actuel = data.prime_actuel
      this.projetForUpdate.courtier_id = data.courtier_id
      this.projetForUpdate.user_id = data.user_id
    },
    deleteDevis(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce projet ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(`/devis/deleteDevis/${id}`)
            .then(res => {
              for (let i = 0; i < this.prospectDevisLocal.enum.getprospectpersonephysique.devis.length; i++) {
                if (this.prospectDevisLocal.enum.getprospectpersonephysique.devis[i].id == id) {
                  this.prospectDevisLocal.enum.getprospectpersonephysique.devis.splice(i, 1)
                  break
                }
              }
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sûr de vouloir supprimer les projets ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("devis/devisDeleteMultiple/",{'devis' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for(let i=0;i<this.prospectDevisLocal.enum.getprospectpersonephysique.devis.length;i++)
                      {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if(this.prospectDevisLocal.enum.getprospectpersonephysique.devis[i].id == res.data.deleted[j].id)
                          {
                            this.prospectDevisLocal.enum.getprospectpersonephysique.devis.splice(i, 1)
                          }
                        }
                      }
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
          case 'Archiver':

          break;
        default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params;
     
    },
    clearDataProjetUpdate() {
      this.projetForUpdate.idEtude = null
      this.projetForUpdate.idDevis = null
      this.projetForUpdate.client = null
      this.projetForUpdate.risque = null
      this.projetForUpdate.assureur = null
      this.projetForUpdate.statut = null
      this.projetForUpdate.num_devis = null
      this.projetForUpdate.date_effet = null
      this.projetForUpdate.date_creation = null
      this.projetForUpdate.budget_propose = null
      this.projetForUpdate.budget_actuel = null
      this.projetForUpdate.courtier_id = null
      this.projetForUpdate.user_id = null
    },
    RecordProjetExterneReturned(data, idCreated) {
      if (idCreated) {
        this.rows.push(data)
      } else {
        const indexProjetFill = this.rows.findIndex(el => el.etude.id === data.etude.id && el.id === data.id)
        this.rows.splice(indexProjetFill, 1)
        this.rows.unshift(data)
      }
    },
    // fetch Data
    fetchRisqueList() {
      this.risquesOptions = []
      this.$http
          .post('/risques/fetchRisqueList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.risquesOptions.push({
                  value: el.id,
                  text: el.label
                })
              })
            }
          })
          .catch(err => {
            this.risquesOptions = []
            console.error(err)
          })
    },
    fetchAssureurList() {
        this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur
                })
              })
            }
          })
          .catch(err => {
              this.assureursOptions = [{
                value: null,
                text: '-- Choisissez --'
              }]
            console.error(err)
          })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    redirectprojet(value) {
      let etudeId = value.etude_id
      this.$store.dispatch('clearData')
      if (value.devis_avancement == 'TARIFICATION') {
        this.$router.push({
          name: 'projets-proposition',
          params: { id: etudeId }
        })
      }
      if (value.devis_avancement == 'SOUSCRIPTION') {
        this.$router.push({
          name: 'projets-souscription',
          params: { id: etudeId }
        })
      }
    },
  },
}
</script>
