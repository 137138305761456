<template>
<div>
    <!-- <h1 class="text-uppercase text-center text-primary">Prochainement</h1> -->
        <div class="mb-2">
            <img class="img-fluid" src="@/assets/images/Prochainement.png" />
        </div>
    <!-- <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-1 mt-1">
              <b-button 
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="md"
                class="ml-1"
                >
                <feather-icon
                    icon="PlusIcon"   
                />
                <span class="align-middle text-uppercase">  Ajouter un sinistre</span></b-button>
          </div>
    </div>
    <b-card>
    
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength,
        rowsPerPageLabel: 'Lignes par page',
        nextLabel: 'Préc',
        prevLabel: 'Suiv',
        allLabel: 'tous',
        ofLabel: 'sur'
      }"
    >
        <div class="text-center align-middle" slot="emptystate">

          <span>Aucune donn&eacute;e disponible dans le tableau</span>

        </div>
    </vue-good-table>
  </b-card> -->
</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,BButton,BCard,BDropdown, BDropdownItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    Ripple,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown, 
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props:['prospectDevis'],
  data() {
    return {
        projets:[],
        pageLength: 5,
        dir: false,
        options: [
            { value: null, text: 'Choisissez' },
            { value: 'Modifier', text: 'Modifier' },
            { value: 'Archiver', text: 'Archiver' },
            { value: 'Supprimer', text: 'Supprimer' },
        ],
        selected: null,
        columns: [
        {
          label: 'N° Sinistre',
          field: 'etude.etude_no',
        },
        {
          label: 'Risque',
          field: 'risque.label',
        },
        {
          label: 'Assureur',
          field: 'risque.label',
        },
        {
          label: 'Statut',
          field: 'statut',
        },
        {
          label: 'Contrat',
          field: 'commercial',
        },
        {
          label: 'N° Dossier',
          field: 'etude.source'
        },
        {
          label: 'Actions',
          field: 'actions'
        }
      ],
      rows: [],
      clients:null,
      searchTerm: '',
      prospectDevisLocal:[]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
      prospectDevis: {
          deep: true,
          handler(val){
              this.prospectDevisLocal = val
              this.rows=this.prospectDevisLocal.enum.getprospectpersonephysique.devis
          }
      }
  },
  methods: {

    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
  }
}
</script>

