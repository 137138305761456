<template>
  <div>

    <div  v-if="can('Import contract') || can('Update contract')">
      <actions-contrat :client-id="joindre_client" :contrat-id="joindre_contrat"
                         :client-type="client_type_id" />

    </div>
    <b-sidebar
        v-if="sideBarIsActive"
        id="add_contrat_sidebar"
        aria-labelledby="sidebar-no-header-title"
        backdrop
        bg-variant="white"
        no-header
        right
        shadow
        width="400px"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Nouveau contrat
          </h5>

          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />
        </div>
        <validation-observer ref="addContratValidations">
          <b-form ref="addContratForm" class="p-2" @submit.prevent="addContrat">
            <!-- <h1 class="text-uppercase">informations</h1> -->

            <h4>CONTRAT</h4>
            <b-form-group label="N° contrat*" label-for="num_contart">
            <validation-provider
              #default="{ errors }"
              name="n° de contrat"
              rules="required"
            >
              <b-form-input
                v-model="new_contrat.num_contrat"
                :state="errors.length > 0 ? false : null"
                id="num_contrat"
                trim
                placeholder="N° de contrat"
                :disabled="editContrat"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>

            <b-form-group label="Risque *" label-for="risque">
              <validation-provider
                  #default="{ errors }"
                  name="risque"
                  rules="required"
              >
                <b-form-select
                    id="risque"
                    v-model="new_contrat.risque_selected"
                    :disabled="editContrat"
                    :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option value="null"
                  >Choisissez
                  </b-form-select-option
                  >
                  <b-form-select-option
                    v-for="risque in risques"
                    :value="risque.id"
                    :key="risque.id"
                  >
                    {{ risque.label }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Assureur *" label-for="assureur">
              <validation-provider
                  #default="{ errors }"
                  name="assureur"
                  rules="required"
              >
                <b-form-select
                    id="assureur"
                    v-model="new_contrat.assureur_selected"
                    :disabled="editContrat"
                    :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option value="null"
                  >Choisissez
                  </b-form-select-option
                  >
                  <b-form-select-option
                      v-for="assureur in assureurs"
                      :key="assureur.id"
                      :value="assureur.id"
                  >
                    {{ assureur.nom_com_assureur }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date d'effet *" label-for="date-effet">
              <validation-provider
                  #default="{ errors }"
                  name="date d'effet"
                  rules="required"
              >
                <flat-pickr
                    id="date-effet"
                    v-model="new_contrat.date_effet"
                    :config="configDate"
                    :disabled="editContrat"
                    class="form-control"
                    placeholder="date d'effet"
                    :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : ''"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Echéance anniversaire" label-for="anive_effet">
              <flat-pickr
                  id="date-effet"
                  :config="configDate"
                  v-model="new_contrat.date_aniv"
                  class="form-control"
                  :disabled="editContrat"
                  placeholder="Echéance anniversaire"
              />
            </b-form-group>

            <b-form-group label="Contrat concurrence*" label-for="contrat-concurrence">
              <validation-provider #default="{ errors }" name="Contrat concurrence" rules="required">
              <div class="demo-inline-spacing" id="contrat-concurrence">
                <b-form-radio
                  v-model="new_contrat.concurrence"
                  name="concurrence-radios"
                  value="1"
                >
                  oui
                </b-form-radio>
                <b-form-radio
                  v-model="new_contrat.concurrence"
                  name="concurrence-radios"
                  value="0"
                >
                  non
                </b-form-radio>
              </div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de fractionnement" label-for="fraction">
                <b-form-select
                    id="fraction"
                    v-model="new_contrat.fraction_selected"
                    :disabled="editContrat"
                    :options="fraction"
                />
            </b-form-group>
            <b-form-group label="Statut *" label-for="statut_contrat">
              <validation-provider
                  #default="{ errors }"
                  name="statut"
                  rules="required"
              >
              <b-form-select
                v-model="new_contrat.statut_selected"
                :options="statut_contrat"
                id="statut_contrat"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                label="Modalité de paiement"
                label-for="type_payement"
            >
                <b-form-select
                    id="type_payement"
                    v-model="new_contrat.payement_type_selected"
                    :disabled="editContrat"
                    :options="type_payement"

                />
            </b-form-group>
            <!-- <h1 class="text-uppercase">Le budget</h1> -->

            <b-form-group label="Prime TTC *" label-for="budget">
              <validation-provider
                  #default="{ errors }"
                  name="budget"
                  rules="required"
              >
              <b-form-input
                v-model="new_contrat.budget"
                id="budget"
                trim
                placeholder="10000,00"
                :disabled="editContrat"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Taux de commission *" label-for="txcom">
              <validation-provider
                  #default="{ errors }"
                  name="taux de commission"
                  rules="required"
              >
              <b-form-input
                v-model="new_contrat.txcom"
                id="txcom"
                trim
                placeholder="10,00"
                :disabled="editContrat"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <h4>RATTACHEMENT</h4>
            <b-form-group label="Courtier" label-for="rattachement">
              <b-form-input
                  id="rattachement"
                  v-model="new_contrat.rattachement"
                  disabled
                  trim
              />
            </b-form-group>
            <b-form-group label="Commercial" label-for="rattachement_user" v-if="false">
              <b-form-select
                  id="rattachement_user"
                  v-model="new_contrat.commercial"
                  :disabled="editContrat"
              >
                <b-form-select-option :value="null">
                  commercial
                </b-form-select-option>
                <b-form-select-option v-for="commercial in commercialOptions" :value="commercial.id" :key="commercial.id">{{commercial.nom}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <!-- <h4>BUDGET</h4> -->
              <h4>DOCUMENTS</h4>
             <app-collapse>
                <app-collapse-item title="Documents de souscription">
                  <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document" >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Conditions particulières'" @click="openConditionParticulier">
                          <b-form-file type="file" id="openConditionParticulier" class="d-none" v-model="new_contrat.fileConditionParticulier" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Conditions particulières" :value="new_contrat.fileConditionParticulier ? new_contrat.fileConditionParticulier.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document" >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Bulletin d\'adhésion signé'" @click="openBulteinAdhesionSigne">
                          <b-form-file type="file" id="openBulteinAdhesionSigne" class="d-none" v-model="new_contrat.fileBulteinAdhésionSigne" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Bulletin d'adhésion signé" :value="new_contrat.fileBulteinAdhésionSigne ? new_contrat.fileBulteinAdhésionSigne.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document" >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Fiche d\'information et de conseil'" @click="openFicheInformationConseil">
                          <b-form-file type="file" id="openFicheInformationConseil" class="d-none" v-model="new_contrat.fileFicheInformationConseil" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Fiche d'information et de conseil" :value="new_contrat.fileFicheInformationConseil ? new_contrat.fileFicheInformationConseil.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                </app-collapse-item>
                <app-collapse-item title="Documents produit">
                  <b-form-group class="mt-2" label-for="h-document"  >
                  <validation-provider #default="{ errors }" name="document" >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'IPID'" @click="openfileIpid">
                          <b-form-file type="file" id="openfileIpid" class="d-none" v-model="new_contrat.fileIpid" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="IPID" :value="new_contrat.fileIpid ? new_contrat.fileIpid.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Conditions generale'" @click="openFileConditionsGenerale">
                          <b-form-file type="file" id="openFileConditionsGenerale" class="d-none" v-model="new_contrat.fileConditionsGenerale" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Condition générale" :value="new_contrat.fileConditionsGenerale ? new_contrat.fileConditionsGenerale.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document" >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Notice d\'information'" @click="openFileNoticeInformation">
                          <b-form-file type="file" id="openFileNoticeInformation" class="d-none" v-model="new_contrat.fileNoticeInformation" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Notice D'information" :value="new_contrat.fileNoticeInformation ? new_contrat.fileNoticeInformation.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="mt-2" label-for="h-document">
                  <validation-provider #default="{ errors }" name="document" >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Fiche d\'information et de conseil'" @click="openFileTableauGarantie">
                          <b-form-file type="file" id="openFileTableauGarantie" class="d-none" v-model="new_contrat.fileTableauGarantie" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Tableau de garantie" :value="new_contrat.fileTableauGarantie ? new_contrat.fileTableauGarantie.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
                </app-collapse-item>
           </app-collapse>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-2"
                  type="submit"
                  variant="primary"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">
        <b-button
            v-b-toggle.add_contrat_sidebar
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="ml-1"
            size="md"
            variant="primary"
            @click="resetFormContrat"
        >
          <feather-icon icon="PlusIcon"/>
          <span class="align-middle text-uppercase">
            Importer un contrat</span
          ></b-button
        >
      </div>
    </div>
    <b-card>
      <!-- table -->
      <vue-good-table
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
          @on-selected-rows-change="selectionChanged"
          :rows="rows"
          :rtl="direction"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
          :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
          }"
      >
        <div slot="emptystate" class="text-center align-middle">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'contrat_num'">
            {{ props.row.source === 'EXTERNE' ? props.row.police_no : props.row.contrat_no }}
          </span>
          <span v-if="props.column.field === 'client'" class="text-nowrap">
            <span>
              {{ $_.get(prospectDevisLocal.enum, 'prospect.nom', null) }}
              {{ $_.get(prospectDevisLocal.enum, 'prospect.prenom', null) }}
            </span>
          </span>

          <span v-else-if="props.column.field === 'commercial_name'" class="text-nowrap">
            <span>
              {{
                $_.get(props.row.commercial, 'personne_physique.nom', null)
              }} {{ $_.get(props.row.commercial, 'personne_physique.prenom', null) }}
            </span>
          </span>

          <span v-else-if="props.column.field === 'source'">
          <b-badge :variant="sourceVariant(props.row.source)">
            {{ props.row.source }}
          </b-badge>

        </span>
          <span v-else-if="props.column.field === 'statut'">
                          {{ changeText(props.row.statut) }}
        </span>

          <span v-else-if="props.column.field === 'assureur'" class="text-nowrap">
            <span>
              {{
                $_.get(
                    prospectDevisLocal.enum,
                    'devis.assureur.assureur_nom',
                    null
                )
              }}
            </span>
          </span>

          <span v-else-if="props.column.field == 'concurrence_statut'">
            <template v-if="props.row.concurrence == 1">Oui</template>
            <template v-else>Non</template>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                  no-caret
                  toggle-class="text-decoration-none"
                  variant="link"
              >
                <template v-slot:button-content>
                  <feather-icon
                      class="text-body align-middle mr-25"
                      icon="MoreVerticalIcon"
                      size="16"
                  />
                </template>
                <b-dropdown-item v-if="props.row.source === 'EXTERNE'"
                    v-b-toggle.add_contrat_sidebar
                    @click="getDataForUpdate(props.row)"
                >
                  <feather-icon class="mr-50" icon="Edit2Icon"/>
                  <span>Modifier</span>
                </b-dropdown-item>
                 <b-dropdown-item  v-b-toggle.sidebar-importNewContrat @click="joindreFile(props.row.contrable_id,props.row.id,props.row.contrable.type)">
                  <feather-icon class="mr-50" icon="PaperclipIcon"/>
                  <span>Joindre</span>
                </b-dropdown-item>
                <b-dropdown-item  @click="JoindreContrat(props.row.id)">
                  <feather-icon class="mr-50" icon="SearchIcon"/>
                  <span>Visualiser</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteContrat(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
          <b-row class="mt-2 align-items-center">
            <b-col md="4">
              <b-input-group>
                  <b-form-select
                      v-model="selected_action"
                      :options="options"
                  />
                  <b-input-group-append>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="action_masse(selected_action)"
                    >
                      <span>Valider </span>
                      <feather-icon
                          class="mr-50"
                          icon="ArrowRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col>
              <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <!-- pagination -->

      </vue-good-table>
    </b-card>
       <b-modal centered size="lg" id="modal-display-document" ref="modal-display-document" :hide-footer="hideFooter" >
             <autres-document :courtier-id="joindre_courtier" :contrat-id-doc="joindre_contrat_doc"/>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { confirmed, digits, integer, password, required } from '@validations'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormFile,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BPagination,
  BRow,
  BSidebar,
  VBToggle,
  VBTooltip,
  BModal
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import AutresDocument from '@/views/portefeuilles/contrats/AutresDocument'
import ActionsContrat from '@/views/tiers/prospects/ActionsContrat'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Ripple,
    BFormFile,
    BModal,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSidebar,
    flatPickr,
    BFormSelectOption,
    BFormDatepicker,
    BInputGroupAppend,
    VBToggle,
    VBTooltip,
    AutresDocument,
    ActionsContrat,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: ['prospectDevis'],

  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      commercialOptions: [],
      hideFooter: false ,
      joindre_client :null,
      joindre_contrat :null,
      clientsOptions: [],
      client_type_id :null,
      joindre_courtier :null,
      joindre_contrat_doc :null,
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        },
      },
      source: [{
        1: 'EXTERNE',
        2: 'INTERNE',
      },
        {
          1: 'light-primary',
          2: 'light-warning'
        }],
      sideBarIsActive: false,
      required,
      confirmed,
      password,
      integer,
      digits,
      projets: [],
      pageLength: 5,
      dir: false,
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        // {
        //   value: 'Modifier',
        //   text: 'Modifier'
        // },
        // {
        //   value: 'Archiver',
        //   text: 'Archiver'
        // },
        {
          value: 'Supprimer',
          text: 'Supprimer'
        },
      ],
      selected_action: null,
      columns: [
        {
          label: 'N° Contrat',
          field: 'contrat_num',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Risque",
          field: "risque.label",
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Assureur',
          field: 'assureur.nom_com_assureur',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Source',
          field: 'source',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'effet',
          field: 'date_effet',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Budget',
          field: 'prime',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Commercial",
          field: "commercial_name",
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Concurrence",
          field: "concurrence_statut",
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: "Actions",
          field: "actions",
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
      ],
      rows: [],
      clients: null,
      searchTerm: '',
      prospectDevisLocal: [],
      editContrat: false,
      /// formulaire nouveau contrat
      new_contrat: {
        id: null,
        client:null,
        num_contrat: null,
        risque_selected: null,
        assureur_selected: null,
        date_effet: null,
        date_aniv: null,
        fraction_selected: null,
        payement_type_selected: null,
        fileBulteinAdhésionSigne:null,
        fileConditionParticulier:null,
        fileFicheInformationConseil:null,
        fileIpid:null,
        fileConditionsGenerale:null,
        fileTableauGarantie:null,
        fileNoticeInformation: null,
        // rattachement_user_selected: null,
        rattachement: null,
        budget: null,
        txcom: null,
        statut_selected: null,
        contrable: {
          id: null,
          type: null,
        },
        commercial: null,
        commercial_id: null,
        concurrence: 0,
      },
      commercial_id: null,
      risques: [],
      assureurs: [],
      type_payement: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'CB',
          text: 'CB'
        },
        {
          value: 'CHEQUE',
          text: 'Chèque'
        },
        {
          value: 'PRELEVEMENT',
          text: 'Prélèvement'
        },
      ],
      fraction: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'MENSUELLE',
          text: 'Mensuel'
        },
        {
          value: 'ANNUELLE',
          text: 'Annuel'
        },
        {
          value: 'TRIMESTRIELLE',
          text: 'Trimestriel'
        },
        {
          value: 'SEMESTRIELLE',
          text: 'Semestriel'
        },
      ],
      statut_contrat: [
        {
          value: null,
          text: 'Choisissez'
        },
        // { value: "CONTRAT", text: "contrat" },
        // { value: "MISE_EN_DEMEURE", text: "mise en demeure" },
        // { value: "RESILIE", text: "résilie" },
        // { value: "SANS_EFFET", text: "sans effet" },

        {
          value: 'EN_COURS',
          text: 'En cours'
        },
        {
          value: 'SUSPENDU',
          text: 'Suspendu'
        },
        {
          value: 'MISE_EN_DEMEURE',
          text: 'Mise en demeure'
        },
        {
          value: 'RESILIE',
          text: 'Résilié'
        },
        {
          value: 'SANS_EFFET',
          text: 'Sans effet'
        }
      ],
      rattachement_users: [],
      selectedrows_masse : []
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sourceVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        EXTERNE: 'light-primary',
        INTERNE: 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    clientsOptions: {
      handler(val) {
        console.log(val[0]);
        this.new_contrat.client = val[0]
        console.log(this.new_contrat.client)
      }
    },
    prospectDevis: {
      deep: true,
      immediate: true,
      handler(val) {
        this.prospectDevisLocal = val
        this.rows =
            this.prospectDevisLocal.enum.getprospectpersonephysique.contrat
        this.risques = this.prospectDevisLocal.enum.risques
        this.assureurs = this.prospectDevisLocal.enum.assureurs
        this.new_contrat.rattachement = 'Cabinet ' + this.prospectDevisLocal.enum.courtieruser.courtier.prenom_dirigeant + ' ' + this.prospectDevisLocal.enum.courtieruser.courtier.nom_dirigeant
        this.rattachement_users =
            this.prospectDevisLocal.enum.courtieruser.user
        this.new_contrat.contrable.id =
            this.prospectDevisLocal.enum.prospect.id
        this.new_contrat.contrable.type =
            this.prospectDevisLocal.enum.prospect.type
        this.commercial_id = this.prospectDevis.enum.getprospectpersonephysique.distributeur.user_id
      },
    },
  },
  methods: {
   // open File Document Souscription  //
     openConditionParticulier: () => {
      document.getElementById('openConditionParticulier').click()
    },

     openBulteinAdhesionSigne: () => {
      document.getElementById('openBulteinAdhesionSigne').click()
    },
    openFicheInformationConseil: () => {
      document.getElementById('openFicheInformationConseil').click()
    },
  //Open File Document Produits //
    openfileIpid: () => {
      document.getElementById('openfileIpid').click()
    },
    openFileConditionsGenerale: () => {
      document.getElementById('openFileConditionsGenerale').click()
    },
    openFileTableauGarantie: () => {
      document.getElementById('openFileTableauGarantie').click()
    },
    openFileNoticeInformation: () => {
      document.getElementById('openFileNoticeInformation').click()
    },
  joindreFile(client,contrat,clientType){
    console.log(contrat)
      this.joindre_client = client
      this.joindre_contrat = contrat
      this.client_type_id =clientType
      },
  JoindreContrat(contrat){
          this.joindre_contrat_doc=contrat
          if (this.joindre_contrat_doc) {
            this.$refs['modal-display-document'].show()
        }

        },
    deleteContrat(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce contrat ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(`/contrat/deleteContrat/${id}`)
            .then(res => {
              for (let i = 0; i < this.prospectDevisLocal.enum.getprospectpersonephysique.contrat.length; i++) {
                if (this.prospectDevisLocal.enum.getprospectpersonephysique.contrat[i].id == id) {
                  this.prospectDevisLocal.enum.getprospectpersonephysique.contrat.splice(i, 1)
                  break
                }
              }
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sûr de vouloir supprimer les contrats ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("contrat/deletecontratmultiple/",{'contrats' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for(let i=0;i<this.prospectDevisLocal.enum.getprospectpersonephysique.contrat.length;i++)
                      {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if(this.prospectDevisLocal.enum.getprospectpersonephysique.contrat[i].id == res.data.deleted[j].id)
                          {
                            this.prospectDevisLocal.enum.getprospectpersonephysique.contrat.splice(i, 1)
                          }
                        }
                      }
                      this.messageToast(res.data.success, 'Succès', 'success')
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
          case 'Archiver':

          break;
        default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params;
    },
    changeText(statut) {
      switch (statut) {
        case 'EN_COURS':
          return 'En cours'
          break
        case 'SUSPENDU':
          return 'Suspendu'
          break
        case 'MISE_EN_DEMEURE':
          return 'Mise en demeure'
          break
        case 'RESILIE':
          return 'Résilié'
          break
        case 'SANS_EFFET':
          return 'Sans effet'
          break
        case 'TEMPORAIRE':
          return 'Temporaire'
          // eslint-disable-next-line no-unreachable
          break
        default:
          return null
          break
      }
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    formattingDate(date) {
      if (date) {
        let date_formatting = date
        date_formatting = date_formatting.split('-')
        date_formatting =
            date_formatting[2] +
            '/' +
            date_formatting[1] +
            '/' +
            date_formatting[0]
        return date_formatting
      }
      return null
    },
    formatDateAdd(date) {
      if (date) {
        date = date.split('/')
        date = date[2] + '-' + date[1] + '-' + date[0]
      }
      return date ? moment(date)
          .add(1, 'year')
          .format('DD/MM/yyyy') : ''
    },
    addContrat() {
      this.$refs.addContratValidations.validate()
          .then((success) => {
            if (success) {
              if (!this.editContrat) {
                this.new_contrat.commercial_id = this.commercial_id
                const formData = new FormData()

                formData.append('client', JSON.stringify(this.checkIsNull(this.new_contrat.client)))
                formData.append('courtier_id',this.checkIsNull(this.currentUser.courtier_user[0].courtier_id))
                formData.append('commercial_id', this.new_contrat.commercial_id)
                formData.append('fileBulteinAdhésionSigne', this.checkIsNull(this.new_contrat.fileBulteinAdhésionSigne))
                formData.append('fileConditionParticulier', this.checkIsNull(this.new_contrat.fileConditionParticulier))
                formData.append('fileFicheInformationConseil', this.checkIsNull(this.new_contrat.fileFicheInformationConseil))
                formData.append('fileIpid', this.checkIsNull(this.new_contrat.fileIpid))
                formData.append('fileConditionsGenerale', this.checkIsNull(this.new_contrat.fileConditionsGenerale))
                formData.append('fileTableauGarantie', this.checkIsNull(this.new_contrat.fileTableauGarantie))
                formData.append('fileNoticeInformation', this.checkIsNull(this.new_contrat.fileNoticeInformation))
                formData.append('id', this.checkIsNull(this.new_contrat.id))
                formData.append('num_contrat', this.checkIsNull(this.new_contrat.num_contrat))
                formData.append('risque_selected', this.checkIsNull(this.new_contrat.risque_selected))
                formData.append('assureur_selected', this.checkIsNull(this.new_contrat.assureur_selected))
                formData.append('date_effet', this.checkIsNull(this.new_contrat.date_effet))
                formData.append('date_aniv', this.checkIsNull(this.new_contrat.date_aniv))
                formData.append('fraction_selected', this.checkIsNull(this.new_contrat.fraction_selected))
                formData.append('payement_type_selected', this.checkIsNull(this.new_contrat.payement_type_selected))
                formData.append('rattachement', this.checkIsNull(this.new_contrat.rattachement))
                formData.append('budget', this.checkIsNull(this.new_contrat.budget))
                formData.append('txcom', this.checkIsNull(this.new_contrat.txcom))
                formData.append('statut_selected', this.checkIsNull(this.new_contrat.statut_selected))
                formData.append('contrable', JSON.stringify(this.checkIsNull(this.new_contrat.contrable)))
                formData.append('commercial', JSON.stringify(this.checkIsNull(this.new_contrat.commercial)))
                formData.append('concurrence',JSON.stringify(new_contrat.concurrence))

                const config = { headers: { 'Content-Type': 'multipart/form-data' } }
                this.$http
                    .post(`contrat/saveContratPortefeuille`, formData, config)
                    .then((r) => {
                      this.prospectDevisLocal.enum.getprospectpersonephysique.contrat.unshift(
                          r.data.data
                      )
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Le contrat a été ajouté `,
                          icon: 'UserIcon',
                          variant: 'success',
                        },
                      })
                      this.resetFormContrat()
                      this.editContrat = false
                      this.sideBarIsActive = false
                    })
                    .catch((err) => {
                      console.log(err)
                    })
              } else {
                this.$http
                    .put(`contrat/updatestatutcontrat`, {
                      id: this.new_contrat.id,
                      statut: this.new_contrat.statut_selected,
                    })
                    .then((r) => {
                      for (
                          let i = 0;
                          i <
                          this.prospectDevisLocal.enum.getprospectpersonephysique
                              .contrat.length;
                          i++
                      ) {
                        if (
                            this.prospectDevisLocal.enum.getprospectpersonephysique.contrat[i].id == r.data.data.id) {
                          this.prospectDevisLocal.enum.getprospectpersonephysique.contrat[i].statut = r.data.data.statut
                          break
                        }
                      }
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Le contrat a été modifié`,
                          icon: 'UserIcon',
                          variant: 'success',
                        },
                      })
                      this.resetFormContrat()
                      this.sideBarIsActive = false
                    })
                    .catch((err) => {
                      console.log(err)
                    })
              }
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: `Veuillez remplir les champs obligatoires`,
              //     icon: 'UserIcon',
              //     variant: 'danger',
              //   },
              // })
            }
          })
    },
    getDataForUpdate(row) {
      this.editContrat = true
      this.sideBarIsActive = true
      //console.log(row)
      this.new_contrat.id = row.id
      if(row.source == 'EXTERNE')
      {
        this.new_contrat.num_contrat = row.police_no;
      }
      else
      {
        this.new_contrat.num_contrat = row.contrat_no;
      }
      this.new_contrat.risque_selected = row.risque_id
      this.new_contrat.assureur_selected = row.assureur_id
      this.new_contrat.date_effet = this.formattingDate(row.date_effet)
      this.new_contrat.fraction_selected = row.fractionnement
      this.new_contrat.payement_type_selected = row.mode_paiement
      this.new_contrat.budget = row.prime
      this.new_contrat.txcom = row.taux
      this.new_contrat.statut_selected = row.statut
      this.new_contrat.commercial = row.user_id
      this.new_contrat.rattachement =
          'Cabinet ' +
          row.courtier.prenom_dirigeant +
          ' ' +
          row.courtier.nom_dirigeant
      this.new_contrat.concurrence = row.concurrence
    },
    resetFormContrat() {
      this.editContrat = false
      this.sideBarIsActive = true
      for (var key in this.new_contrat ) {
        if(key != 'rattachement' && key != 'contrable'&& key !='client')
        {
          this.new_contrat[key] = null;
        }
        if(key == 'concurrence')
        {
          this.new_contrat[key] = 0;
        }
      }
    },
   fetchClientList() {
      this.clientsOptions = []
      this.$http
          .post('/prospect/fetchClientList', { courtier_user_id: this.currentUser.courtier_user[0].id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.clientsOptions.push({
                  id: el.id,
                  type: el.type,
                  nom: el.nom,
                  categorie: el.categorie,
                  statutFiche: el.statutFiche,
                  commercial: el.commercial_id
                })
              })
            }
          })
          .catch(err => {
            this.clientsOptions = []
            console.error(err)
          })
    },
    updateContratStatut() {
      this.$refs.addContratValidations.validate()
          .then((success) => {
            if (success) {
              this.$http
                  .put(`contrat/updatestatutcontrat`, this.new_contrat)
                  .then((r) => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Le contrat a ete Modifie`,
                        icon: 'UserIcon',
                        variant: 'success',
                      },
                    })
                    this.$refs.addContratForm.reset()
                    this.sideBarIsActive = false
                  })
                  .catch((err) => {
                    console.log(err)
                  })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Veuillez remplir les champs obligatoires`,
                  icon: 'UserIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
  },
  mounted() {
    this.hideFooter =true
    this.fetchClientList()
    this.fetchDataCommercialByCourtier()
  }
}
</script>
