<template>
  <div>
    <b-sidebar
      id="sidebar-entreprise"
      aria-labelledby="sidebar-no-header-title"
      no-header
      bg-variant="white"
      shadow
      backdrop
      right
      width="400px"
      v-if="sidebarEntrepriseIsActive"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Entreprise
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer ref="EntrepriseValidations">
          <b-form
            class="p-2"
            @submit.prevent="addOrUpdateEntreprise"
            autocomplete="off"
          >
            <b-form-group label="Lien*" label-for="type_lien_prof_entreprise">
              <validation-provider
                #default="{ errors }"
                name="Lien"
                rules="required"
              >
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="entreprise.type_lien_prof"
                    name="type_lien_prof-radios"
                    value="salarie"
                    :state="errors.length > 0 ? false : null"
                  >
                    Salarié(e)
                  </b-form-radio>
                  <b-form-radio
                    v-model="entreprise.type_lien_prof"
                    name="type_lien_prof-radios"
                    value="dirigeant"
                    :state="errors.length > 0 ? false : null"
                  >
                    Dirigeant(e)
                  </b-form-radio>
                </div>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Forme Juridique*" label-for="form_juridique">
              <validation-provider
                #default="{ errors }"
                name="Forme Juridique"
                rules="required"
              >
                <b-form-select
                  v-model="entreprise.form_juridique"
                  id="form_juridique"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option value="null" disabled
                    >Sélectionner une forme juridique</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(form, value) in FormeJuridique"
                    :key="'f' + value"
                    :value="form.value"
                  >
                    {{ form.text }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Dénomination*" label-for="denomination">
              <validation-provider
                #default="{ errors }"
                name="Dénomination"
                rules="required|min:3"
              >
                <b-form-input
                  v-model="entreprise.denomination"
                  :state="errors.length > 0 ? false : null"
                  id="denomination"
                  trim
                  placeholder="Dénomination"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Adresse*" label-for="adresse">
              <validation-provider
                #default="{ errors }"
                name="adresse"
                rules="required|min:10"
              >
                <b-form-input
                  v-model="entreprise.adresse"
                  :state="errors.length > 0 ? false : null"
                  id="adresse"
                  trim
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Code postal*"
              label-for="code_postal_entreprise"
            >
              <validation-provider
                #default="{ errors }"
                name="Code postal"
                rules="required|digits:5"
              >
                <b-form-input
                  v-model="entreprise.code_postal"
                  :state="errors.length > 0 ? false : null"
                  id="code_postal_entreprise"
                  trim
                  placeholder="Code postal"
                  v-mask="'#####'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Ville*" label-for="ville_entreprise">
              <validation-provider
                #default="{ errors }"
                name="Ville"
                rules="required"
              >
                <b-form-select
                  v-model="entreprise.ville"
                  :state="errors.length > 0 ? false : null"
                  id="ville_entreprise"
                >
                  <b-form-select-option selected :value="null"
                    >Selectionner une ville</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="ville in Villes"
                    :value="ville.id"
                    :key="ville.id"
                    >{{ ville.ville }}</b-form-select-option
                  >
                </b-form-select>
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Téléphone*" label-for="tel">
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="required" :rules="{regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/}"
              >
                <b-form-input
                  v-model="entreprise.tel"
                  id="tel"
                  trim
                  placeholder="Téléphone"
                  :state="errors.length > 0 ? false : null"
                  v-mask="'## ## ## ## ##'"
                />
                 <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email*" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="entreprise.email"
                  id="email"
                  trim
                  placeholder="Email"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Siret" label-for="siret">
              <validation-provider
                #default="{ errors }"
                name="Siret"
                rules="digits:14"
              >
              <b-form-input
                v-model="entreprise.siret"
                id="siret"
                trim
                placeholder="Siret"
                v-mask="'##############'"
              />
              <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date de création" label-for="date-creation">
              <validation-provider
                #default="{ errors }"
                name="Date de création"
                reles="required" :rules="{regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/}"
              >

                <flat-pickr
                  id="date-naissance"
                  v-model="entreprise.dateCreation"
                  class="form-control"
                  placeholder="Date de naissance"
                  :config="configDateEntreprise"
                  :class="errors.length > 0 ? 'is-invalid' : ''"
                  maxlength="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
              <template v-if="entreprise.id">
                Mettre à jour
              </template>
              <template v-else>
                Enregistrer
              </template>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-conjoint"
      aria-labelledby="sidebar-no-header-title"
      no-header
      bg-variant="white"
      shadow
      backdrop
      right
      width="400px"
      v-if="sidebarConjointIsActive"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Conjoint
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer ref="ConjointValidations">
          <b-form
            class="p-2"
            @submit.prevent="addOrUpdateConjoint"
            autocomplete="off"
          >
            <b-form-group label="Nom*" label-for="nom">
              <validation-provider
                #default="{ errors }"
                name="Nom"
                rules="required|min:3"
              >
                <b-form-input
                  v-model="conjoint.nom"
                  :state="errors.length > 0 ? false : null"
                  id="nom"
                  trim
                  placeholder="Nom"
                  class="text-uppercase"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Prénom*" label-for="prenom">
              <validation-provider
                #default="{ errors }"
                name="Prénom"
                rules="required|min:3"
              >
                <b-form-input
                  v-model="conjoint.prenom"
                  :state="errors.length > 0 ? false : null"
                  id="prenom"
                  trim
                  placeholder="Prénom"
                  class="text-capitalize"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email*" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="conjoint.email"
                  :state="errors.length > 0 ? false : null"
                  id="email"
                  trim
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date de naissance*" label-for="date-naissance">
              <validation-provider
                #default="{ errors }"
                name="Date de naissance"
                rules="required" :rules="{regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/}"
              >
                <flat-pickr
                  id="date-naissance"
                  v-model="conjoint.dateNaissance"
                  class="form-control"
                  placeholder="Date de naissance"
                  :config="configDateConjoint"
                  :class="errors.length > 0 ? 'is-invalid' : ''"
                  maxlength="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="Numero SS" label-for="numero_ss">
              <validation-provider
                #default="{ errors }"
                name="Numero SS"
                rules="min:13"
              >
              <b-form-input
                v-model="conjoint.numero_ss"
                id="numero_ss"
                trim
                placeholder="Numero SS"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
              <template v-if="conjoint.id">
                Mettre à jour
              </template>
              <template v-else>
                Enregistrer
              </template>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-enfants"
      aria-labelledby="sidebar-no-header-title"
      no-header
      bg-variant="white"
      shadow
      backdrop
      right
      width="400px"
      v-if="sidebarEnfantsIsActive"
      :style="sidebarEnfantsIsActive ? 'display:flex !important;' : ''"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Enfant
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer ref="addChildrensValidations">
          <b-form class="p-2" @submit.prevent="addChildrens">
            <b-form-group label="Nom" label-for="nom">
              <validation-provider
                #default="{ errors }"
                name="Nom*"
                rules="required"
              >
                <b-form-input
                  v-model="enfant.nom"
                  :state="errors.length > 0 ? false : null"
                  id="nom"
                  trim
                  placeholder="Nom"
                  class="text-uppercase"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Prénom" label-for="prenom">
              <validation-provider
                #default="{ errors }"
                name="Prénom*"
                rules="required"
              >
                <b-form-input
                  v-model="enfant.prenom"
                  :state="errors.length > 0 ? false : null"
                  id="prenom"
                  trim
                  placeholder="Prénom"
                  class="text-capitalize"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date de naissance*" label-for="date-naissance">
              <validation-provider
                #default="{ errors }"
                name="Date de naissance*"
                :rules="{regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/}"
              >
                <flat-pickr
                  id="date-naissance"
                  v-model="enfant.dateNaissance"
                  class="form-control"
                  placeholder="Date de naissance"
                  :config="configDateEnfant"
                  :state="errors.length > 0 ? false : null"
                  maxlength="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-sidebar
      id="edit_prospect_sidebar"
      aria-labelledby="sidebar-no-header-title"
      no-header
      bg-variant="white"
      shadow
      backdrop
      right
      width="400px"
      v-if="sidebarProspectIsActive"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            Modifier client
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <validation-observer ref="editProspectValidations">
          <b-form class="p-2" @submit.prevent="editProspect">
            <!-- <h1 class="text-uppercase">informations</h1> -->

            <b-form-group
                label="Categorie*"
                label-for="categorie_personne"
              >
              <b-form-select
                v-model="edit_prospect.personne_type"
                id="categorie_personne"
                >
                <b-form-select-option value="null" disabled
                  >Choisissez *</b-form-select-option
                >
                <b-form-select-option value="PARTICULIER">Particulier</b-form-select-option>
                <b-form-select-option value="PROFESSIONNEL">Professionnel</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="edit_prospect.statut_fiche  === 'Prospect'"   label="Souhaitez-vous changer le statut du prospect en client ?">
              <b-form-radio-group
                  v-model="edit_prospect.isClient"
                  :options="optionsChangedToClient"
                  class="demo-inline-spacing"
                  name="radio-inline"
              />
            </b-form-group>
            <!-- <b-form-group label="Identifiant du client*" label-for="id_client">
              <validation-provider
                #default="{ errors }"
                name="Identifiant du client"
                rules="required"
              >
                <b-form-input
                  v-model="edit_prospect.client_no"
                  :state="errors.length > 0 ? false : null"
                  id="id_client"
                  trim
                  placeholder="N° de Client"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Civilité*" label-for="civilite">
              <validation-provider
                #default="{ errors }"
                name="Civilité"
                :rules="editProspect.civilite == null ? 'required' : ''"
              >
                <b-form-select
                  v-model="edit_prospect.civilite"
                  id="civilite"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option value="null" disabled
                    >Civilité *</b-form-select-option
                  >
                  <b-form-select-option value="MR"> Mr. </b-form-select-option>
                  <b-form-select-option value="MME">
                    Mme.
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nom client*" label-for="nom">
              <validation-provider
                #default="{ errors }"
                name="Nom client"
                rules="required"
              >
                <b-form-input
                  v-model="edit_prospect.nom"
                  :state="errors.length > 0 ? false : null"
                  id="nom"
                  trim
                  placeholder="Nom client"
                  class="text-uppercase"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Prénom*" label-for="prenom">
              <validation-provider
                #default="{ errors }"
                name="Prénom"
                rules="required"
              >
                <b-form-input
                  v-model="edit_prospect.prenom"
                  :state="errors.length > 0 ? false : null"
                  id="prenom"
                  trim
                  placeholder="Prénom"
                  class="text-capitalize"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

             <!-- rules="required:digits:13" -->
            <!-- <b-form-group label="N° Sécurité Sociale*" label-for="num_ss">
              <validation-provider
                #default="{ errors }"
                name="N° Sécurité Sociale"

              >
                <b-form-input
                  v-model="edit_prospect.num_secu"
                  :state="errors.length > 0 ? false : null"
                  id="num_ss"
                  trim
                  placeholder="N° Sécurité Sociale*"
                  v-mask="'#############'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <b-form-group label="Date de naissance" label-for="date-naissance">
                <flat-pickr
                  id="date-naissance"
                  v-model="edit_prospect.date_naissance"
                  class="form-control"
                  placeholder="Date de naissance"
                  :config="configDateConjoint"
                  maxlength="10"
                />
            </b-form-group>

            <b-form-group
              label="Situation familiale*"
              label-for="situation_familiale"
            >
                <b-form-select
                  v-model="edit_prospect.situation_familiale"
                  id="situation_familiale"
                >
                  <b-form-select-option value="null" disabled
                    >Situation familiale *</b-form-select-option
                  >
                  <b-form-select-option value="CONCUBIN"
                    >Concubin(e)</b-form-select-option
                  >
                  <b-form-select-option value="CELIBATAIRE"
                    >Célibataire</b-form-select-option
                  >
                  <b-form-select-option value="DIVORCE"
                    >Divorcé(e)</b-form-select-option
                  >
                  <b-form-select-option value="MARIE"
                    >Marié(e)</b-form-select-option
                  >
                  <b-form-select-option value="SEPARE"
                    >Séparé(e)</b-form-select-option
                  >
                  <b-form-select-option value="VEUF"
                    >Veuf(ve)</b-form-select-option
                  >
                  <b-form-select-option value="PACSE"
                    >Pacsé(e)</b-form-select-option
                  >
                </b-form-select>
            </b-form-group>

            <b-form-group label="Enfants à charge" label-for="nbr_enfant">
              <b-form-input
                v-model="edit_prospect.enfantCharge"
                :value="prospectChildCount"
                id="nbr_enfant"
                trim
                placeholder="Enfants à charge"
                type="number"
                min="0"
              />
            </b-form-group>

            <b-form-group label="N° et libellé de la voie*" label-for="adresse">
              <validation-provider
                #default="{ errors }"
                name="N° et libellé de la voie"
                rules="required"
              >
                <b-form-input
                  v-model="edit_prospect.moyen_contact.libelle"
                  :state="errors.length > 0 ? false : null"
                  id="adresse"
                  trim
                  placeholder="N° et libellé de la voie"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Complément d'adresse"
              label-for="complement_adresse"
            >
              <b-form-input
                v-model="edit_prospect.moyen_contact.complement_adresse"
                id="complement_adresse"
                trim
                placeholder="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)"
              />
            </b-form-group>

            <b-form-group label="Lieu-dit ou BP" label-for="lieu_dit">
              <b-form-input
                v-model.trim="edit_prospect.moyen_contact.lieu_dit_ou_bp"
                id="lieu_dit"
                trim
                placeholder="Lieu-dit ou BP"
              />
            </b-form-group>

            <b-form-group label="Code Postal*" label-for="code_postal">
              <validation-provider
                #default="{ errors }"
                name="CODE POSTAL"
                rules="required|digits:5"
              >
                <b-form-input
                  v-model.trim="edit_prospect.moyen_contact.code_postal"
                  id="code_postal"
                  placeholder="CODE POSTAL"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Ville*" label-for="ville">
              <validation-provider
                #default="{ errors }"
                name="Ville"
                :rules="
                  edit_prospect.moyen_contact.ville == null ? 'required' : ''
                "
              >
                <b-form-select
                  v-model="edit_prospect.moyen_contact.ville"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-select-option selected :value="null"
                    >Selectionner une ville</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="ville in Villes"
                    :value="ville.id"
                    :key="ville.id"
                    >{{ ville.ville }}</b-form-select-option
                  >
                </b-form-select>
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Téléphone mobile*" label-for="tel_mobile">
              <validation-provider
                #default="{ errors }"
                name="Téléphone mobile"
                rules="required" :rules="{regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/}"
              >
                <b-form-input
                  id="tel_mobile"
                  placeholder="Téléphone mobile"
                  :state="errors.length > 0 ? false : null"
                  v-model.trim="edit_prospect.moyen_contact.tel"
                  v-mask="'## ## ## ## ##'"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Téléphone fixe" label-for="tel_fix">
              <validation-provider
                #default="{ errors }"
                name="Téléphone fixe"
                :rules="{regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/}"
              >
              <b-form-input
                id="tel_fix"
                placeholder="Téléphone fixe"
                v-model.trim="edit_prospect.moyen_contact.tel_secondaire"
                v-mask="'## ## ## ## ##'"
              />
              <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email*" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                  type="email"
                  v-model.trim="edit_prospect.moyen_contact.email"
                />
                <small class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Activité professionnelle" label-for="activite">
                <b-form-select
                  v-model="edit_prospect.statut"
                  id="activite"
                >
                  <b-form-select-option :value="null" 
                    >Choisissez</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(activite, value) in ACTIVITE_DATA"
                    :key="'a' + value"
                    :value="activite.value"
                  >
                    {{ activite.name }}
                  </b-form-select-option>
                </b-form-select>
            </b-form-group>

            <!-- <b-form-group label="Profession" label-for="profession">
              <validation-provider
                #default="{ errors }"
                name="Profession"
                rules="required"
              >
                <b-form-select
                  v-model="edit_prospect.profession"
                  :state="errors.length > 0 ? false : null"
                  id="profession"
                >
                  <b-form-select-option value="null" disabled
                    >Profession *</b-form-select-option
                  >
                  <b-form-select-option value="AGRICULTEUR"
                    >Agriculteur</b-form-select-option
                  >
                  <b-form-select-option value="ARTISAN_COMMERCANT"
                    >Artisan Commerçant</b-form-select-option
                  >
                  <b-form-select-option value="CADRE"
                    >Cadre</b-form-select-option
                  >
                  <b-form-select-option value="INGENIEUR"
                    >Ingénieur</b-form-select-option
                  >
                  <b-form-select-option value="CHEF_D_ENTREPRISE"
                    >Chef d'entreprise</b-form-select-option
                  >
                  <b-form-select-option value="COMMERCANT"
                    >Commerçant</b-form-select-option
                  >
                  <b-form-select-option value="EMPLOYE"
                    >Employé(e)</b-form-select-option
                  >
                  <b-form-select-option value="AGENT_DE_LA_FONCTION_PUBLIQUE"
                    >Agent de la fonction publique</b-form-select-option
                  >
                  <b-form-select-option value="EN_RECHERCHE_D_EMPLOI"
                    >En recherche d'emploi</b-form-select-option
                  >
                  <b-form-select-option value="ENSEIGNANT"
                    >Enseignant(e)</b-form-select-option
                  >
                  <b-form-select-option value="ETUDIANT"
                    >Etudiant(e)</b-form-select-option
                  >
                  <b-form-select-option value="HOMME_OU_FEMME_AU_FOYER"
                    >Homme ou femme au foyer</b-form-select-option
                  >
                  <b-form-select-option value="OUVRIER"
                    >Ouvrier(e)</b-form-select-option
                  >
                  <b-form-select-option value="PROFESSION_INTERMEDIARE"
                    >Profession intermédiaire</b-form-select-option
                  >
                  <b-form-select-option value="AGENT_DE_MAITRISE"
                    >Agent de maîtrise</b-form-select-option
                  >
                  <b-form-select-option value="PROFESSION_LIBERALE"
                    >Profession libérale</b-form-select-option
                  >
                  <b-form-select-option value="RETRAITE"
                    >Retraité(e)</b-form-select-option
                  >
                  <b-form-select-option value="TECHNICIEN"
                    >Technicien</b-form-select-option
                  >
                  <b-form-select-option value="INACTIF"
                    >inactif</b-form-select-option
                  >
                  <b-form-select-option value="AUTRE"
                    >Autre</b-form-select-option
                  >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Revenu Annuel" label-for="revenu_annuel">
              <b-form-select
                v-model="edit_prospect.revenu_annuel"
                id="revenu_annuel"
              >
                <b-form-select-option value="null" disabled
                  >Revenu Annuel</b-form-select-option
                >
                <b-form-select-option value="PLUS_3001"
                  >Plus de 3 001 €</b-form-select-option
                >
                <b-form-select-option value="DE_1501_A_3000"
                  >De 1 501 € à 3 000 €</b-form-select-option
                >
                <b-form-select-option value="DE_801_A_1500"
                  >De 801 € à 1 500 €</b-form-select-option
                >
                <b-form-select-option value="MOINS_801"
                  >Jusqu'à 800 €</b-form-select-option
                >
              </b-form-select>
            </b-form-group> -->

            <b-form-group
              label="Régime de sécurité sociale"
              label-for="regime_obligatoire"
            >
              <b-form-select
                  v-model="edit_prospect.regime_obligatoire"
                  id="regime_obligatoire"

                >
                  <b-form-select-option value="null" disabled
                    >Régime Obligatoire</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(regime, value) in REGIMESOCIAL"
                    :key="'r' + value"
                    :value="regime.value"
                  >
                    {{ regime.name }}
                  </b-form-select-option>
               </b-form-select>
            </b-form-group>

            <!-- utilisateur list -->
            <validation-provider #default="{ errors }" name="commercial" rules="required">
              <b-form-group :state="errors.length > 0 ? false : null" label="Commercial*" label-for="commercial">
                <v-select v-model="commercial" :close-on-select="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions" class="flex-grow-1"
                  input-id="commercial" label="nom" placeholder="Commercial">
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm" />
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune commercial disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                        errors[0]
                      }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="sidebarProspectIsActive = true"
                v-if="can('Update client')"
              >
                Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <b-card>
          <b-row>
            <!-- Right Col: Table -->
            <b-col cols="10">
              <h4 class="font-weight-bolder">
                {{
                  $_.get(prospectDataLocal.enum, "prospect.civilite", null) +
                  ". " +
                  $_.get(prospectDataLocal.enum, "prospect.nom", null) +
                  " " +
                  $_.get(prospectDataLocal.enum, "prospect.prenom", null)
                }}
              </h4>
              <b-row>
                <b-col cols="12" xl="6">
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder">Né(e) le : </span>
                      </th>
                      <td class="pb-50">
                        {{
                          formattingDate(
                            $_.get(
                              prospectDataLocal.enum,
                              "prospect.date_naissance",
                              null
                            )
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder"
                          >Situation familiale :</span
                        >
                      </th>
                      <td class="pb-50">
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.situation_familiale",
                            null
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder"
                          >Enfants à charge :</span
                        >
                      </th>
                      <td class="pb-50">
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.enfantCharge",
                            "0"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder"
                          >Régime de sécurité sociale :</span
                        >
                      </th>
                      <td class="pb-50">
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.regime_obligatoire",
                            "---"
                          )
                        }}
                      </td>
                    </tr>
                  </table>
                </b-col>
                <b-col cols="12" xl="6">
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder"
                          >Téléphone fixe :
                        </span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.tel_secondaire",
                            "---"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder"
                          >Téléphone portable :
                        </span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.tel",
                            null
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder">Email :</span>
                      </th>
                      <td class="pb-50 text-lowercase">
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.email",
                            null
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <span class="font-weight-bolder"
                          >Adresse complète :</span
                        >
                      </th>
                      <td class="pb-50 text-capitalize">
                        <!-- {{prospectDataLocal.enum.prospect.moyen_contact.adresse ? prospectDataLocal.enum.prospect.moyen_contact.adresse : '-----------------'}}  -->
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.libelle",
                            null
                          )
                        }}
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.complement_adresse",
                            null
                          )
                        }}
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.lieu_dit_ou_bp",
                            null
                          )
                        }}
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.code_postal",
                            null
                          )
                        }}
                        {{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.moyen_contact.ville.commune",
                            null
                          )
                        }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="pb-50">
                        <span class="font-weight-bold">Affilé: </span>
                      </th>
                      <td class="pb-50">
                        <span>{{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.regime_obligatoire",
                            null
                          )
                        }}</span>
                      </td>
                    </tr> -->
                    <!-- <tr>
                      <th class="pb-50">
                        <span class="font-weight-bold">N° de SS: </span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        <span>{{
                          $_.get(
                            prospectDataLocal.enum,
                            "prospect.num_secu",
                            null
                          )
                        }}</span>
                      </td>
                    </tr> -->
                  </table>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="2" class="text-right" >
              <feather-icon
                icon="Edit3Icon"
                size="26"
                class="cursor-pointer text-primary"
                v-b-toggle.edit_prospect_sidebar
                @click="getDataForEdit"
                v-if="can('Update client')"
              />
            </b-col>
          </b-row>
          <app-collapse accordion type="margin">
            <app-collapse-item title="Entreprise" v-if="entreprise.id">
              <h4 class="font-weight-bolder d-flex justify-content-between">
                <span>
                  {{
                    $_.get(entreprise, "form_juridique", null) +
                    ". " +
                    $_.get(entreprise, "denomination", null)
                  }}
                </span>
                <span
                  class="cursor-pointer"
                  @click="deleteEntreprise(entreprise.id)"
                >
                  <feather-icon icon="TrashIcon" size="22" />
                </span>
              </h4>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <!-- <tr>
                      <th class="pb-50">
                        <span class="font-weight-bold">Date de création: </span>
                      </th>
                      <td class="pb-50">
                        {{
                          formattingDate(
                            $_.get(
                              entreprise,
                              "dateCreation",
                              null
                            )
                          )
                        }}
                      </td>
                    </tr> -->
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Adresse complète</span>
                    </th>
                    <td class="pb-50 text-lowercase">
                      {{
                        $_.get(entreprise, "adresse", null) +
                        " " +
                        $_.get(entreprise, "code_postal", null)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Ville: </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.get(entreprise, "ville_text", null) }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </app-collapse-item>

            <app-collapse-item title="Conjoint" v-if="conjoint.id">
              <h4 class="font-weight-bolder d-flex justify-content-between">
                <span>
                  {{
                    $_.get(conjoint, "nom", null) +
                    " " +
                    $_.get(conjoint, "prenom", null)
                  }}
                </span>
                <span
                  class="cursor-pointer"
                  @click="deleteConjoint(conjoint.id)"
                >
                  <feather-icon icon="TrashIcon" size="22" />
                </span>
              </h4>
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Né(e) le: </span>
                    </th>
                    <td class="pb-50">
                      {{ $_.get(conjoint, "dateNaissance", null) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Email: </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ $_.get(conjoint, "email", null) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold" v-if="conjoint.numero_ss"
                        >Numero SS</span
                      >
                    </th>
                    <td class="pb-50 text-lowercase">
                      {{ $_.get(conjoint, "numero_ss", null) }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </app-collapse-item>

            <app-collapse-item
              title="Enfant(s)"
              v-show="prospectChildCount > 0"
            >
              <vue-good-table
                :columns="columns_enfant"
                :rows="rows_enfant"
                theme="my-theme"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item @click="editChildren(props.row)">
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>Éditer</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="deleteEnfant(props.row.lienable_id)"
                        >
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Supprimer</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </app-collapse-item>
          </app-collapse>
        </b-card>
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4" style="display:none;">
        <b-card>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50 text-left">
                <h4 class="font-weight-bold">Entreprise:</h4>
              </th>
              <td class="pb-50 text-capitalize text-right">
                <template v-if="entreprise.id">
                  <feather-icon
                    icon="EditIcon"
                    size="26"
                    class="cursor-pointer text-primary"
                    v-b-toggle.sidebar-entreprise
                    @click="sidebarEntrepriseIsActive = true"
                    v-if="can('Update client')"
                  />
                </template>
                <template v-else>
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="26"
                    class="cursor-pointer text-primary"
                    v-b-toggle.sidebar-entreprise
                    @click="sidebarEntrepriseIsActive = true"
                    v-if="can('Update client')"
                  />
                </template>
              </td>
            </tr>
            <tr>
              <th class="pb-50 text-left">
                <h4 class="font-weight-bold">Conjoint:</h4>
              </th>
              <td class="pb-50 text-capitalize text-right">
                <template v-if="conjoint.id">
                  <feather-icon
                    icon="EditIcon"
                    size="26"
                    class="cursor-pointer text-primary"
                    v-b-toggle.sidebar-conjoint
                    @click="sidebarConjointIsActive = true;ConjointUpdate = true"
                    v-if="can('Update client')"
                  />
                </template>
                <template v-else>
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="26"
                    class="cursor-pointer text-primary"
                    v-b-toggle.sidebar-conjoint
                    @click="sidebarConjointIsActive = true"
                    v-if="can('Update client')"
                  />
                </template>
              </td>
            </tr>
            <tr>
              <th class="pb-50 text-left">
                <h4 class="font-weight-bold">
                  Enfants
                  <!-- <feather-icon
                    icon="SmileIcon"
                    size="20"
                    class="cursor-pointer"
                    :class="prospectChildCount > 0 ? 'text-primary':'text-secondary'"
                    :badge="prospectChildCount"
                  /> -->
                  <b-badge variant="light-primary">
                    {{ prospectChildCount }}
                  </b-badge>
                </h4>
              </th>
              <td class="pb-50 text-capitalize text-right">
                <template>
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="26"
                    class="cursor-pointer text-primary"
                    v-b-toggle.sidebar-enfants
                    @click="sidebarEnfantsIsActive = true"
                    v-if="can('Update client')"
                  />
                </template>
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Data from "../../../shared/constants/prospect";
import Besoin from "../../../shared/constants/risquebesoin"
import Activite from "../../../shared/constants/activite"
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BCardHeader,
  BCardBody,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BSidebar,
  VBToggle,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BFormSelectOption,
  BFormDatepicker,
  BFormRadio,
  BFormRadioGroup,
  BAvatar
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  props: ["prospectData"],
  components: {
    VueGoodTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    VBToggle,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    ToastificationContent,
    BFormDatepicker,
    AppCollapse,
    AppCollapseItem,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    BAvatar
  },
  mounted() {
    this.fetchDataCommercialByCourtier()
  },
  data() {
    //config datepicker
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxEnfant = new Date(today);

    const minDate = new Date(today);
    minDate.setYear(minDate.getFullYear() - 18);
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      commercialOptions: [],
      REGIMESOCIAL:Besoin['REGIMESOCIAL'],
      ACTIVITE_DATA : Activite['ACTIVITE'],
      config: {
        dateFormat: "d/m/Y",
      },
      configDateConjoint: {
        dateFormat: "d/m/Y",
        allowInput: true,
        maxDate:minDate,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;
            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      configDateEnfant: {
        dateFormat: "d/m/Y",
        allowInput: true,
        // minDate:minDate,
        maxDate:today,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      configDateEntreprise: {
        dateFormat: "d/m/Y",
        allowInput: true,
        maxDate:today,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      adult: minDate,
      //entreprise
      FormeJuridique: Data["FORMEJURIDIQUE"],
      entreprise: {
        id: null,
        type_lien_prof: null,
        form_juridique: null,
        denomination: null,
        adresse: null,
        code_postal: null,
        ville: null,
        ville_text: null,
        tel: null,
        email: null,
        siret: null,
        dateCreation: null,
      },
      // enfant list
      columns_enfant: [
        {
          label: "Nom",
          field: "lienable.nom",
        },
        {
          label: "Prénom",
          field: "lienable.prenom",
        },
        {
          label: "Date de naissance",
          field: "lienable.date_naissance",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd/MM/yyyy",
        },
        {
          label: "Actions",
          field: "action",
        },
      ],
      rows_enfant: [],
      prospectDataLocal: [],
      //isActive: false,
      sidebarEnfantsIsActive: false,
      sidebarEntrepriseIsActive: false,
      sidebarConjointIsActive: false,
      sidebarProspectIsActive: false,
      // count charge enfant
      countEnfant: 0,
      prospectChildCount: 0,
      //config datepicker
      maxDateEnfant: maxEnfant,
      // add children
      //ConjointUpdate
      enfant: {
        id: null,
        type_lien_fam: "ENFANT",
        nom: null,
        prenom: null,
        dateNaissance: null,
      },
      // conjoint manager
      conjoint: {
        id: null,
        type_lien_fam: "CONJOINT",
        nom: null,
        prenom: null,
        email: null,
        dateNaissance: null,
        numero_ss: null,
        lienable_id: null,
      },
      //edit prospect form
      edit_prospect: {
        personne_type:'PARTICULIER',
        isClient:false,
        client_no: null,
        civilite: null,
        nom: null,
        prenom: null,
        num_secu: null,
        date_naissance: null,
        situation_familiale: null,
        enfantCharge: null,
        moyen_contact: {
          adresse: null,
          complement_adresse: null,
          lieu_dit_ou_bp: null,
          code_postal: null,
          ville: null,
          tel: null,
          tel_secondaire: null,
          email: null,
        },
        statut: null,
        profession: null,
        revenu_annuel: null,
        regime_obligatoire: null,
        actif_commercial: null
      },
      optionsChangedToClient: [
        {value: true, text: 'Oui'}  ,
        {value: false, text: 'Non'}
      ],
      commercial: {
        id: null,
        nom: null,
        type: null
      }
    };
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },

  watch: {
    prospectData: {
      deep: true,
      handler(val) {
        this.prospectDataLocal = val;
        this.countEnfant = this.prospectDataLocal.enum.prospect.enfantCharge;
        let countE = 0;
        this.rows_enfant = [];
        for (
          let i = 0;
          i < this.prospectDataLocal.enum.prospect.prospect.length;
          i++
        ) {
          if (
            this.prospectDataLocal.enum.prospect.prospect[i].lien_familial ==
            "ENFANT"
          ) {
            countE++;
            this.rows_enfant.push(
              this.prospectDataLocal.enum.prospect.prospect[i]
            );
          } else if (
            this.prospectDataLocal.enum.prospect.prospect[i].lien_familial ==
            "CONJOINT"
          ) {
            (this.conjoint.id =
              this.prospectDataLocal.enum.prospect.prospect[i].lienable.id),
              (this.conjoint.type_lien_fam = "CONJOINT"),
              (this.conjoint.nom =
                this.prospectDataLocal.enum.prospect.prospect[i].lienable.nom),
              (this.conjoint.prenom =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.prenom),
              (this.conjoint.email =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.moyen_contact.email),
              (this.conjoint.dateNaissance = this.formattingDate(
                this.prospectDataLocal.enum.prospect.prospect[i].lienable
                  .date_naissance
              )),
              (this.conjoint.numero_ss =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.num_secu);
            this.conjoint.lienable_id =
              this.prospectDataLocal.enum.prospect.prospect[i].id;
          } else if (
            this.prospectDataLocal.enum.prospect.prospect[i]
              .lien_professionnel == "dirigeant" ||
            this.prospectDataLocal.enum.prospect.prospect[i]
              .lien_professionnel == "salarie"
          ) {
            (this.entreprise.id =
              this.prospectDataLocal.enum.prospect.prospect[i].lienable.id),
              (this.entreprise.type_lien_prof =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lien_professionnel),
              (this.entreprise.form_juridique =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.forme_juridique),
              (this.entreprise.denomination =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.denomination_commercial),
              (this.entreprise.code_postal =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.moyen_contact.code_postal),
              (this.entreprise.ville =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.moyen_contact.ville),
              (this.entreprise.ville_text =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.moyen_contact.ville.commune);
            (this.entreprise.tel =
              this.prospectDataLocal.enum.prospect.prospect[
                i
              ].lienable.moyen_contact.tel),
              (this.entreprise.email =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.moyen_contact.email),
              (this.entreprise.dateCreation = this.formattingDate(
                this.prospectDataLocal.enum.prospect.prospect[i].lienable
                  .date_creation
              )),
              (this.entreprise.siret =
                this.prospectDataLocal.enum.prospect.prospect[
                  i
                ].lienable.siret);
            this.entreprise.adresse =
              this.prospectDataLocal.enum.prospect.prospect[
                i
              ].lienable.moyen_contact.libelle;
          }
        }
        this.prospectChildCount = countE;
      },
    },
    "edit_prospect.moyen_contact.code_postal": {
      immediate: true,
      handler(val) {
        this.GetVille(val, "prospect");
      },
    },
    "entreprise.code_postal": {
      immediate: true,
      handler(val) {
        this.GetVille(val, "entreprise");
      },
    },
  },
  methods: {
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    getVilleById() {
      if (this.entreprise.ville) {
        this.$http
          .get(`helpers/ville/${this.entreprise.ville}`)
          .then((res) => {
            this.entreprise.ville_text = res.data.commune;
          })
          .catch((err) => {});
      } else {
        this.entreprise.ville_text = null;
      }
    },
    getDataForEdit() {
      let commercialData = this.prospectDataLocal.enum.getprospectpersonephysique.distributeur.user.personne_physique
      this.edit_prospect = _.cloneDeep(this.prospectDataLocal.enum.prospect);
      this.commercial.id   = commercialData.user_id
      this.commercial.nom   = commercialData.prenom + ' ' + commercialData.nom
      this.commercial.type  = commercialData.type
      this.edit_prospect.actif_commercial = commercialData.user_id

      this.edit_prospect.personne_type='PARTICULIER'
      this.edit_prospect.isClient=false
      this.GetVilleLoad(
        this.prospectDataLocal.enum.prospect.moyen_contact.code_postal
      );
      this.edit_prospect.date_naissance = this.formattingDate(this.edit_prospect.date_naissance);
      this.sidebarProspectIsActive = true;
    },
    formattingDate(date) {
      if (date) {
        let date_formatting = date;
        date_formatting = date_formatting.split("-");
        date_formatting =
          date_formatting[2] +
          "/" +
          date_formatting[1] +
          "/" +
          date_formatting[0];
        return date_formatting;
      }
      return null;
    },
    formattingDateSql(date) {
      if (date) {
        let date_formatting = date;
        date_formatting = date_formatting.split("/");
        date_formatting =
          date_formatting[0] +
          "-" +
          date_formatting[1] +
          "-" +
          date_formatting[2];
        return date_formatting;
      }
      return null;
    },
    GetVille(code, type) {
      if (code && code.length == 5) {
        this.$http
          .get(`helpers/${code}/villes`)
          .then((res) => {
            if (res.data?.length > 0) {
              this.Villes = [...res.data];
              if (type == "prospect") {
                this.edit_prospect.moyen_contact.ville = this.Villes[0].id;
              } else if (type == "entreprise") {
                this.entreprise.ville = this.Villes[0].id;
              }
            }
          })
          .catch((err) => {});
      } else {
        this.Villes = [];
      }
    },
    GetVilleLoad(code) {
      if (code) {
        this.$http
          .get(`helpers/${code}/villes`)
          .then((res) => {
            if (res.data?.length > 0) {
              this.Villes = [...res.data];
            }
          })
          .catch((err) => {});
      } else {
        this.Villes = [];
      }
    },
    editProspect() {
      this.$refs.editProspectValidations.validate().then((success) => {
        if (success) {
          this.edit_prospect.commercial = this.commercial
          this.$http
            .post(`prospect/updateorsaveprospecttier`, this.edit_prospect)
            .then((r) => {
              if (r.status) {
                this.prospectDataLocal.enum.prospect = r.data.prospect;
                this.sidebarProspectIsActive = false;
                this.$emit('sync-enum', r.data.prospect.id, r.data.prospect.type)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Le prospect a été modifié`,
                      icon: "UserIcon",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-right",
                    timeout: 4000,
                  }
                );
                if(this.edit_prospect.personne_type=='PROFESSIONNEL'){
                  this.$router.push({
                    name: 'tiers-list',
                    params: {
                      'id': this.prospectDataLocal.enum.prospect.id,
                      'type': this.prospectDataLocal.enum.prospect.type,
                      'interfaceChosen': 'Professionnel'
                    }
                  })
                }
              }
            })
            .catch((e) => {});
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Veuillez remplir les champs obligatoires`,
                icon: "UserIcon",
                variant: "danger",
              },
            },
            {
              position: "top-left",
            }
          );
        }
      });
    },
    addChildrens() {
      this.$refs.addChildrensValidations.validate().then((success) => {
        if (success) {
          this.$http
            .post(`prospect/saveorupdateEnfant`, {
              prospect: this.prospectDataLocal.enum.prospect,
              enfant: this.enfant,
            })
            .then((r) => {
              if (r.status == 200) {
                if (this.enfant.id) {
                  for (
                    let i = 0;
                    i < this.prospectDataLocal.enum.prospect.prospect.length;
                    i++
                  ) {
                    if (
                      this.prospectDataLocal.enum.prospect.prospect[i]
                        .lienable_id == this.enfant.id
                    ) {
                      this.prospectDataLocal.enum.prospect.prospect[
                        i
                      ].lienable = r.data.enfantData.lien[0].lienable;
                      break;
                    }
                  }
                } else {
                  this.prospectDataLocal.enum.prospect.prospect.push(
                    r.data.enfantData.lien[0]
                  );
                }

                this.sidebarEnfantsIsActive = false;
                this.enfant.id = null;

                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `L'enfant a étè enregistré`,
                      icon: "UserPlusIcon",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-left",
                  }
                );
              }
            })
            .catch((e) => {
              this.errors = e.response.data.errors;
            });
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Veuillez remplir les champs obligatoires`,
                icon: "UserIcon",
                variant: "danger",
              },
            },
            {
              position: "top-left",
            }
          );
        }
      });
    },
    addOrUpdateConjoint() {
      this.$refs.ConjointValidations.validate().then((success) => {
        if (success) {
          this.$http
            .post(`prospect/saveorupdateConjoint`, {
              prospect: this.prospectDataLocal.enum.prospect,
              conjoint: this.conjoint,
            })
            .then((r) => {
              if (r.status) {
                this.sidebarConjointIsActive = false;
                this.conjoint.id = r.data.conjointId;
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Le conjoint a étè enregistré`,
                      icon: "UsersIcon",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-left",
                  }
                );
              }
            })
            .catch((e) => {
              this.errors = e.response.data.errors;
            });
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Veuillez remplir les champs obligatoires`,
                icon: "UsersIcon",
                variant: "danger",
              },
            },
            {
              position: "top-left",
            }
          );
        }
      });
    },
    addOrUpdateEntreprise() {
      this.$refs.EntrepriseValidations.validate().then((success) => {
        if (success) {
          this.$http
            .post(`prospect/saveorupdateentreprise`, {
              prospect: this.prospectDataLocal.enum.prospect,
              entreprise: this.entreprise,
            })
            .then((r) => {
              if (r.status) {
                this.sidebarEntrepriseIsActive = false;
                this.entreprise.id = r.data.entrepriseId;
                this.getVilleById();
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `L'entreprise a étè enregistré`,
                      icon: "TrelloIcon",
                      variant: "success",
                    },
                  },
                  {
                    position: "top-left",
                  }
                );
              }
            })
            .catch((e) => {
              this.errors = e.response.data.errors;
            });
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Veuillez remplir les champs obligatoires`,
                icon: "TrelloIcon",
                variant: "danger",
              },
            },
            {
              position: "top-left",
            }
          );
        }
      });
    },
    editChildren(params) {
      this.enfant.id = params.lienable.id;
      this.enfant.type_lien_fam = params.lien_familial;
      this.enfant.nom = params.lienable.nom;
      this.enfant.prenom = params.lienable.prenom;
      this.enfant.dateNaissance = this.formattingDate(
        params.lienable.date_naissance
      );
      this.$root.$emit("bv::toggle::collapse", "sidebar-enfants");
      this.sidebarEnfantsIsActive = true;
    },
    deleteConjoint(prospect) {
      this.$swal({
        title: "Êtes-vous sûr?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("prospect/deleteProspectlien", {
              prospect_id: prospect,
              type: "PERSONNE_PHYSIQUE",
            })
            .then((res) => {
              if (res.data.success) {
                this.conjoint.id = null;
                this.conjoint.type_lien_fam = "CONJOINT";
                this.conjoint.nom = null;
                this.conjoint.prenom = null;
                this.conjoint.email = null;
                this.conjoint.dateNaissance = null;
                this.conjoint.numero_ss = null;
                this.conjoint.lienable_id = null;
              }
            })
            .catch((err) => {});

          this.$swal({
            icon: "success",
            title: "Supprimé!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    deleteEntreprise(prospect) {
      this.$swal({
        title: "Êtes-vous sûr?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("prospect/deleteProspectlien", {
              prospect_id: prospect,
              type: "PERSONNE_MORALE",
            })
            .then((res) => {
              if (res.data.success) {
                this.entreprise.id = null;
                this.entreprise.type_lien_prof = null;
                this.entreprise.form_juridique = null;
                this.entreprise.denomination = null;
                this.entreprise.adresse = null;
                this.entreprise.code_postal = null;
                this.entreprise.ville = null;
                this.entreprise.ville_text = null;
                this.entreprise.tel = null;
                this.entreprise.email = null;
                this.entreprise.siret = null;
                this.entreprise.dateCreation = null;
              }
            })
            .catch((err) => {});

          this.$swal({
            icon: "success",
            title: "Supprimé!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    deleteEnfant(prospect) {
      this.$swal({
        title: "Êtes-vous sûr?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("prospect/deleteProspectlien", {
              prospect_id: prospect,
              type: "PERSONNE_PHYSIQUE",
            })
            .then((res) => {
              if (res.data.success) {
                for (
                  let i = 0;
                  i < this.prospectDataLocal.enum.prospect.prospect.length;
                  i++
                ) {
                  if (
                    this.prospectDataLocal.enum.prospect.prospect[i]
                      .lienable_id == prospect
                  ) {
                    this.prospectDataLocal.enum.prospect.prospect.splice(i, 1);
                    this.prospectChildCount = this.prospectChildCount - 1;
                    break;
                  }
                }
              }
            })
            .catch((err) => {});

          this.$swal({
            icon: "success",
            title: "Supprimé!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
