<template>
  <div>
    <b-overlay
        :show="showLoading"
        no-wrap
    />
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1">
        <h1 class="text-primary font-weight-bolder text-uppercase ml-1">
          Fiche : Particulier
        </h1>

        <span v-if="prospect && prospect.enum" class="mx-1">
          <b-badge  :variant="prospect.enum.getpersonephysiquereclamations.statut_fiche ==='Ancien Client' ? typeClientVariant('Ancien') : typeClientVariant(prospect.enum.getpersonephysiquereclamations.statut_fiche)"> {{
              prospect.enum.getpersonephysiquereclamations.statut_fiche
            }} </b-badge>
        </span>
      </div>
      <div class="mr-1 mb-1">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :to="{ name: 'tiers-list' }"
            class="ml-1 text-uppercase"
            size="md"
            variant="primary"
            id="revenir-liste-client"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </div>
    </div>
    <fiche-tier :prospect-data="prospect" @sync-enum="syncEnum"/>
    <template>
      <b-tabs
          class="tabs-header-bg"
          content-class="mt-2"
          justified
      >
        <b-tab title="devis">
          <projet :prospect-devis="prospect" />
        </b-tab>
        <b-tab title="Contrats">
          <contrat :prospect-devis="prospect"/>
        </b-tab>
        <b-tab title="Documents">
          <document :prospect-devis="prospect"/>
        </b-tab>
        <b-tab
            disabled
            title="Sinistres"
        >
          <sinistre :prospect-devis="prospect"/>
        </b-tab>
        <b-tab
            disabled
            title="Réclamations"
        />
        <b-tab title="Notes">
          <note :prospect-note="prospect"/>
        </b-tab>
        <b-tab
            title="Activités"
        >
          <list-activite :client-type="prospectType" :client-id="prospectId" />
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { BBadge, BButton, BCardText, BTab, BTabs, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FicheTier from './FicheTier.vue'
import Projet from './Projet.vue'
import Document from './Document.vue'
import Contrat from './Contrat.vue'
import Sinistre from './Sinistre.vue'
import Note from './Note.vue'
import listActivite from '../global-components/Activite.vue'

export default {
  components: {
    BBadge,
    FicheTier,
    Projet,
    Document,
    Contrat,
    Sinistre,
    Note,
    BTabs,
    BTab,
    BCardText,
    BButton,
    BOverlay,
    listActivite,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showLoading:true,
      prospectId:null,
      prospectType:null,
      prospect: null,
      projets: null,
      typeClient: [{
        1: 'PARTICULIER',
        2: 'PROFESSIONNEL',
        3: 'ENTREPRISE',
      },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }],
    }
  },
  created() {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'tiers-list' })
    }
    //     this.prospect = this.$route.params.prospect
    const id_prospect = this.$route.params.id
    let type_prospect = this.$route.params.type

    if (!this.$route.params.type) {
      type_prospect = 'PERSONNE_PHYSIQUE'
    }

    this.syncEnum(id_prospect, type_prospect)

  },
  mounted(){
    this.prospectId = this.$route.params.id
    let type_prospect = this.$route.params.type
    if (!this.$route.params.type) {
      type_prospect = 'PERSONNE_PHYSIQUE'
    }
    this.prospectType = type_prospect
  },
  methods: {
    syncEnum(id_prospect, type_prospect) {
          this.$http
        .get(`/prospect/prospectEdit/${id_prospect}/${type_prospect}/`)
        .then(response => {
          this.prospect = response.data
          this.showLoading = false
        })
        .catch(err => {
          this.$router.push({ name: 'tiers-list' })
        })
    },
    typeClientVariant(categorie) {
      try {
        const typeClientColor = {
          /* eslint-disable key-spacing */
          Prospect: 'light-danger',
          Client: 'light-warning',
          Ancien: 'light-success',
          /* eslint-enable key-spacing */
        }
        if (categorie) {
          return typeClientColor[categorie]
        }
        return null
      }catch (e) {
        return null
      }
    }
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style lang="css">
table.vgt-table {
  font-size: 14px !important;
}

.vgt-table thead th {
  padding: 0.5em !important;
  vertical-align: middle;
}
</style>
<style lang="css">
.tabs-header-bg ul {
  background: #fff;
  padding: 5px;
}
</style>
