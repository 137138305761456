<template>
  <div>
    <!-- <b-button v-if="can('Import contract')" v-b-toggle.sidebar-contrat v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="md" variant="primary" @click="$emit('is-update-function', false)"
    >
      <feather-icon icon="PlusIcon"/>
      <span class="align-middle"> Importer un contrat</span>
    </b-button> -->
    <b-sidebar v-if="can('Import contract') || can('Update contract')" id="sidebar-contrat" ref="sideBarContrat"
               backdrop bg-variant="white" no-header right shadow sidebar-class="sidebar-lg"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
            <span v-if="isUpdate"> Modifier le contrat {{ composeData.n_contrat }} </span> <span v-else
          >Nouveau contrat</span>
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <validation-observer ref="contratComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap/>
            <!-- clients list -->
            <h4>INFORMATIONS</h4>

            <validation-provider #default="{ errors }" name="client" rules="required">
              <b-form-group :state="errors.length > 0 ? false : null" label="Client*" label-for="client">
                <v-select v-model="composeData.client" :close-on-select="true" :getOptionKey="client => client.id + client.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="clientsOptions"
                          class="flex-grow-1"
                          input-id="client" label="nom" placeholder="Client"
                >
                  <template #option="{ avatar, nom, categorie }">
                    <b-avatar :src="avatar" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                    <b-badge :variant="categorieVariant(categorie)" class="mr-2 float-right">
                      {{ capitalizeFirstLetter(categorie) }}
                    </b-badge>
                    <!--                    <b-badge :variant="categorie === 'PERSONNE_PHYSIQUE' ? 'light-danger' : 'light-success'" class="mr-2 float-right" >-->
                    <!--                      {{ capitalizeFirstLetter(categorie) }}-->
                    <!--                    </b-badge>-->
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune client disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group v-if="composeData.client && composeData.client.statutFiche === 'Prospect'" label="Souhaitez vous changer le statut du prospect en client ?">
              <b-form-radio-group
                  v-model="composeData.isChangedToProspect"
                  :options="optionsChangedToProspect"
                  class="demo-inline-spacing"
                  name="radio-inline"
              />
            </b-form-group>

            <h4>CONTRAT</h4>
            <!-- N contrat -->
            <b-form-group label="N° contrat*" label-for="num_contrat">
              <validation-provider #default="{ errors }" name="n° contrat" rules="required">
                <b-form-input id="num_contrat" v-model="composeData.n_contrat" :disabled="isUpdate"
                              :state="errors.length > 0 ? false : null" placeholder="N° contrat"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- risque -->
            <b-form-group label="Risque*" label-for="Risque">
              <validation-provider #default="{ errors }" name="risque" rules="required">
                <b-form-select v-model="composeData.risque_id" :options="risquesOptions"
                               :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- assureur -->
            <b-form-group label="Assureur*" label-for="assureur">
              <validation-provider #default="{ errors }" name="assureur" rules="required">
                <b-form-select v-model="composeData.assureur_id" :options="assureursOptions"
                               :state="errors.length > 0 ? false : null"
                >
              
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- date d'effet -->
            <b-form-group label="Date d'effet*" label-for="date-effet">
              <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                <flat-pickr id="date-effet" v-model="composeData.date_effet"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control"
                            placeholder="Date d'effet"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Echeance anniversaire -->
            <b-form-group label="Echéance anniversaire" label-for="echeance-anniversaire">
              <validation-provider #default="{ errors }" name="échéance d'anniversaire" rules="required">
                <flat-pickr id="echeance-anniversaire" v-model="composeData.echeance_anniversaire"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control"
                            placeholder="Echéance d'anniversaire"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
 <!-- -->
 <b-form-group label="Contrat concurrence" label-for="contrat-concurrence">
              <validation-provider #default="{ errors }" name="Contrat concurrence">
                <div class="demo-inline-spacing" id="contrat-concurrence">
                  <b-form-radio v-model="composeData.concurrence" name="concurrence-radios" value="1"> oui </b-form-radio>
                  <b-form-radio v-model="composeData.concurrence" name="concurrence-radios" value="0"> non </b-form-radio>
                </div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Type de paiement -->
            <b-form-group label="Type de paiement" label-for="typeFractionnement">
                <b-form-select v-model="composeData.typeFractionnement" :options="typeFractionnementOptions"
                />
            </b-form-group>

            <!-- statut -->
            <b-form-group label="Statut*" label-for="statut">
              <validation-provider #default="{ errors }" name="statut" rules="required">
                <b-form-select v-model="composeData.statut" :options="statutOptions"
                               :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date de fin" label-for="date-fin" v-if="isRésilié || isTemporaire">
              <validation-provider #default="{ errors }" name="date de fin">
                <flat-pickr id="date-fin" v-model="composeData.date_fin" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date de fin" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Modalité de paiement -->
            <b-form-group label="Modalité de paiement" label-for="Modalite_paiement">
              <!--              <validation-provider #default="{ errors }" name="modalité de paiement" rules="required">-->
              <b-form-select v-model="composeData.modalite_paiement" :options="modalitePaiementOptions"/>
              <!--              :state="errors.length > 0 ? false : null"-->
              <!--                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>-->
              <!--              </validation-provider>-->
            </b-form-group>

            <!-- budget -->
            <b-form-group label="Prime TTC" label-for="budget">
              <validation-provider #default="{ errors }" name="Prime TTC">
                <cleave id="budget" v-model.trim="composeData.budget" :class="errors.length > 0 ? 'is-invalid' : ''"
                        :options="options.number" :raw="false" :state="errors.length > 0 ? false : null"
                        class="form-control" placeholder="10 000,00"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- taux de commission -->
            <validation-provider #default="{ errors }" name="taux de commission">
              <b-form-group label="Taux de commission" label-for="taux_commission">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : ''" append="%">
                  <b-form-input v-model.trim="composeData.taux_commission" :state="errors.length > 0 ? false : null"
                                placeholder="10,00"
                                type="number"
                  />
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <h4>RATTACHEMENT</h4>

            <!-- Courtier -->
            <b-form-group label="Courtier" label-for="courtier">
              <b-form-input id="courtier"
                            v-model="currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial"
                            disabled placeholder="Courtier"
              />
            </b-form-group>

            <!-- utilisateur list -->
            <validation-provider #default="{ errors }" name="commercial" rules="required">
              <b-form-group :state="errors.length > 0 ? false : null" label="Commercial*" label-for="commercial">
                <v-select v-model="composeData.commercial" :close-on-select="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                          class="flex-grow-1"
                          input-id="commercial" label="nom" placeholder="Commercial"
                >
                  <template #option="{ avatar, nom }">
                    <b-avatar :src="avatar" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>

                  <template #selected-option="{ avatar, nom }">
                    <b-avatar :src="avatar" class="border border-white" size="sm"/>
                    <span class="ml-50"> {{ nom }}</span>
                  </template>
                  <div slot="no-options">Aucune commercial disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                        @click="recordContrat"
              > Enregistrers
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-sidebar id="sidebar-importNewContrat" bg-variant="white" sidebar-class="sidebar-lg"   ref="sideBarImportContrat" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Importer un document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="validateImportContratRules">
         <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

        <b-form-group label="Nature de document" label-for="h-famille">
              <validation-provider #default="{ errors }" name="nature de document" rules="required">
                <b-form-select id="h-famille-autre-document" v-model="famille" :options="optionsFamille" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

             <b-form-group label="Type de document" label-for="h-type">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-autre-document" v-model="composeData.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
             <!-- <b-form-group label="Description" label-for="h-Description">
              <b-form-textarea id="h-Description-autre-document" placeholder="Description" rows="3" v-model="composeData.description" />
            </b-form-group> -->
                  <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautredocumentProspet" class="d-none" v-model="composeData.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                 @click="recordDocument"
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
  BInputGroup,
  BSidebar,
  VBToggle,
  BFormFile,
  VBTooltip,
  BFormRadioGroup,
  BInputGroupPrepend,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { ref } from '@vue/composition-api'
import documentService from '@/shared/services/documentService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BBadge,
    Cleave,
    vSelect,
    BFormSelect,
    flatPickr,
    BAvatar,
    VBTooltip,
    BFormTextarea,
    BFormSelectOption,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
     BInputGroupPrepend,
    BOverlay,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
 
    contratId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null,

    },
    clientType: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null,

    },
    clientId: {
      type: Number,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null,

    },
    currentUser: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      isRésilié: false,
      isTemporaire: false,
      showLoading: false,
      required,
      famille:null,
      optionsType:[],
      isUpdateAction: false,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        }
      },
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      optionsChangedToProspect: [
        {value: true, text: 'Oui'}  ,
        {value: false, text: 'Non'}
      ],
      statutOptions: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'EN_COURS',
          text: 'En cours'
        },
        {
          value: 'MISE_EN_DEMEURE',
          text: 'Mise en demeure'
        },
        {
          value: 'RESILIE',
          text: 'Résilié'
        },
        {
          value: 'SANS_EFFET',
          text: 'Sans effet'
        },
        {
          value: 'SUSPENDU',
          text: 'Suspendu'
        },
        {
          value: 'TEMPORAIRE',
          text: 'Temporaire'
        }
      ],
      typeFractionnementOptions: [
      {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'ANNUEL',
          text: 'Annuel'
        },
        {
          value: 'MENSUEL',
          text: 'Mensuel'
        },
        {
          value: 'PRIME_UNIQUE',
          text: 'Prime unique'
        },
        {
          value: 'SEMESTRIEL',
          text: 'Semestriel'
        },
        {
          value: 'TRIMESTRIEL',
          text: 'Trimestriel'
        },
        {
          value: 'VERSEMENTS_LIBRES',
          text: 'Versements libres'
        }
      ],
      modalitePaiementOptions: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'CB',
          text: 'CB'
        },
        {
          value: 'CHEQUE',
          text: 'Chèque'
        },
        {
          value: 'PRELEVEMENT',
          text: 'Prélèvement'
        }
      ],
      composeData: {
        id: null,
        client: null,
        description:null,
        idType:null,
        file:null,
        contrat_Id:null,
        client_Id:null,
        n_contrat: null,
        risque_id: null,
        assureur_id: null,
        date_effet: null,
        echeance_anniversaire: null,
        typeFractionnement: null,
        statut: null,
        modalite_paiement: null,
        commercial: null,
        budget: null,
        taux_commission: null,
        courtier_id: null,
        isChangedToProspect: false,
        concurrence: 0
        
      },
      selected_client: null,
      selected_risque: null,
      selected_assureur: null,
      date_effet: null,
      date_creation: null,

      categories: [{
        1: 'PARTICULIER',
        2: 'PROFESSIONNEL',
        3: 'ENTREPRISE',
      },
        {
          1: 'light-danger',
          2: 'light-warning',
          3: 'light-success'
        }],
    }
  },
  watch: {
    'composeData.statut': {
      handler(val) {
        if (val === 'RESILIE') {
          this.isRésilié = true
        }else if(val === 'TEMPORAIRE'){
          this.isTemporaire = true;
        }
         else {
          this.isRésilié = false
          this.isTemporaire = false;

        }
      }
    },
    contratId:{
      immediate:true,
      handler(val){
       this.composeData.contrat_Id=val
      }
    },
    clientId:{
      immediate:true,
      handler(val){
       this.composeData.client_Id=val

      }
    },

  },
  computed: {
    categorieVariant() {
      const categorieColor = {
        /* eslint-disable key-spacing */
        PARTICULIER: 'light-danger',
        PROFESSIONNEL: 'light-warning',
        ENTREPRISE: 'light-success',
        /* eslint-enable key-spacing */
      }

      return categories => categorieColor[categories]
    },
  },
  created() {
    this.composeData.courtier_id = this.currentUser.courtier_user[0].courtier_id
  },
  methods: {
    hideSideBare() {
      this.$refs.sideBarContrat.hide()
    },
    hideSideBareImportContrat() {
      this.$refs.sideBarImportContrat.hide()
    },
     clearDataSideBarImportContrat() {
       this.famille = null,
       this.composeData.idType=null,
       this.composeData.description=null,
       this.composeData.file=null,
       this.$refs.validateImportContratRules.reset()

     },
      openToSelectFile: () => {
      document.getElementById('fileautredocumentProspet').click()
    },
   changeListoptionType() {
      if (this.famille) {
        this.composeData.idFamille = this.famille.id
        this.composeData.idType = null
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },
     recordDocument() {
      this.$refs.validateImportContratRules.validate().then(success => {
        if (success) {
          // Set formData

          const formData = new FormData()
          // Append the rest of your form data
          formData.append('tiers', this.composeData.id)
          formData.append('type_tiers',this.clientType)
          formData.append('file', this.composeData.file)
          formData.append('type_doc_id', this.composeData.idType)
          formData.append('courtier_id', this.composeData.courtier_id)
          formData.append('description', '')
          // formData.append('description', this.composeData.description)
          formData.append('contrat_id', this.composeData.contrat_Id)
          formData.append('client_id', this.composeData.client_Id)
          formData.append('etude_id', this.composeData.etude_id)
          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/importerNewDocumentContrat', formData, config)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-document-courtier-returned', res.data.data, res.data.message)
                this.messageToast(res.data.message, 'Succès', 'success')
                this.clearDataSideBarImportContrat()
                this.hideSideBareImportContrat()
              } else {
                this.messageToast('Erreur lors de creation de nouveau document.', 'Erreur', 'error')
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      })
    },

    // Methode additional
    formatDateAdd(date) {
      return date ? moment(date, 'DD/MM/YYYY')
          .add(1, 'year')
          .format('DD/MM/YYYY') : null
    },
    clearDataSideBar() {
      this.composeData.id = null
      this.composeData.client = null
      this.composeData.n_contrat = null
      this.composeData.risque_id = null
      this.composeData.assureur_id = null
      this.composeData.date_effet = null
      this.composeData.echeance_anniversaire = null
      this.composeData.typeFractionnement = null
      this.composeData.statut = null
      this.composeData.modalite_paiement = null
      this.composeData.commercial = null
      this.composeData.budget = null
      this.composeData.taux_commission = null
      this.composeData.isChangedToProspect = false
      this.composeData.concurrence = 0

      this.$refs.contratComposeRules.reset()
    },
    // record Data contrat
    methodRecordData() {
      // statutFiche
      this.showLoading = true
      const formData = new FormData()
          // Append the rest of your form data
          formData.append('id', this.checkIsNull(this.composeData.id))
          formData.append('description', this.checkIsNull(this.composeData.description))
          formData.append('client',JSON.stringify(this.composeData.client))
          formData.append('commercial_id', this.composeData.commercial_id)
          formData.append('fileBulteinAdhésionSigne', this.checkIsNull(this.composeData.fileBulteinAdhésionSigne))
          
          formData.append('fileConditionParticulier', this.checkIsNull(this.composeData.fileConditionParticulier))
          formData.append('fileFicheInformationConseil', this.checkIsNull(this.composeData.fileFicheInformationConseil))
          formData.append('fileIpid', this.checkIsNull(this.composeData.fileIpid))
          formData.append('fileConditionsGenerale', this.checkIsNull(this.composeData.fileConditionsGenerale))
          formData.append('fileTableauGarantie', this.checkIsNull(this.composeData.fileTableauGarantie))
          formData.append('fileNoticeInformation', this.checkIsNull(this.composeData.fileNoticeInformation))     
          formData.append('contrat_Id', this.checkIsNull(this.composeData.contrat_Id))
          formData.append('client_Id', this.checkIsNull(this.composeData.client_Id))
          formData.append('n_contrat', this.checkIsNull(this.composeData.n_contrat))
          formData.append('risque_id', this.checkIsNull(this.composeData.risque_id))
          formData.append('assureur_id', this.checkIsNull(this.composeData.assureur_id))
          formData.append('date_effet', this.checkIsNull(this.composeData.date_effet))
          formData.append('echeance_anniversaire', this.checkIsNull(this.composeData.echeance_anniversaire))
          formData.append('typeFractionnement', this.checkIsNull(this.composeData.typeFractionnement))
          formData.append('statut', this.checkIsNull(this.composeData.statut))
          formData.append('modalite_paiement', this.checkIsNull(this.composeData.modalite_paiement))
          formData.append('commercial', JSON.stringify( this.checkIsNull(this.composeData.commercial)))
          formData.append('budget', this.checkIsNull(this.composeData.budget))
          formData.append('taux_commission', this.checkIsNull(this.composeData.taux_commission))
          formData.append('courtier_id', this.checkIsNull(this.composeData.courtier_id))
          formData.append('isChangedToProspect', this.checkIsNull(this.composeData.isChangedToProspect))
          formData.append('concurrence', this.checkIsNull(this.composeData.concurrence))
          // console.log(JSON.stringify(this.projet.client))

          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      this.$http
          .post('/contrat/recordContrat', formDatan, config)
          .then(res => {
            if (res.data.success) {
              this.showLoading = false
              this.$emit('record-contrat-returned', res.data.data, res.data.message)
              this.clearDataSideBar()
              this.hideSideBare()
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })

    },
    recordContrat() {
      this.$refs.contratComposeRules.validate()
          .then(success => {
            if (success) {
              this.methodRecordData()
            }
          })
    }
  },
 setup() {
    const optionsFamille = ref([])
    const fetchDocumentNatureWithTypes = documentService()
    fetchDocumentNatureWithTypes
      .then(success => {
        optionsFamille.value = []
           success.data.filter(function(a) {return a.libelle==="Documents de souscription" ||  a.libelle==='Documents produits';}).forEach(el => {
          optionsFamille.value.push({
            value: { id: el.id, typeDocument: el.documentype },
            text: el.libelle
          })
        })
      })
      .catch(errors => {
        console.log(errors)
        optionsFamille.value = []
      })

    return {
      fetchDocumentNatureWithTypes,
      optionsFamille
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
