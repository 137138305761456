<template>
  <div>
    <b-sidebar id="add_document_sidebar" aria-labelledby="sidebar-no-header-title" no-header bg-variant="white" shadow backdrop right width="400px" v-if="sideBarIsActive">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau Document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="addDocumentValidations">
          <b-form class="p-2" @submit.prevent="AddDocument" ref="addDocumentForm">
            <!-- <h1 class="text-uppercase">informations</h1> -->
            <b-form-group label="Nom de document *" label-for="nom_document">
              <validation-provider #default="{ errors }" name="nom de document" rules="required">
                <b-form-input v-model="new_document.nom" :state="errors.length > 0 ? false : null" id="nom_document" trim placeholder="Nom de document" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Famille de document *" label-for="famille_document">
              <validation-provider #default="{ errors }" name="famille de document" rules="required">
                <b-form-select v-model="new_document.famille" id="famille_document" :state="errors.length > 0 ? false : null" @change="docTypeFill">
                  <b-form-select-option value="null">-- Choisissez --</b-form-select-option>
                  <b-form-select-option v-for="famille_document in famille_documents" :value="famille_document.id" :key="famille_document.id">
                    {{ famille_document.libelle }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de document *" label-for="type_document">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select v-model="new_document.type" id="type_document" :state="errors.length > 0 ? false : null">
                  <b-form-select-option value="null">-- Choisissez --</b-form-select-option>
                  <b-form-select-option v-for="type_document in type_documents" :value="type_document.id" :key="type_document.id">
                    {{ type_document.type }}
                  </b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Description" label-for="description">
              <!-- <validation-provider #default="{ errors }" name="Description" rules="required"> -->
              <b-form-textarea v-model="new_document.description" trim placeholder="Description" rows="5" no-resize id="description" />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
            </b-form-group>

            <!-- <b-form-group label="Importer un document (maximum 10Mo)" label-for="import_document"> -->
<!--              <validation-provider #default="{ errors }" vid="document" name="document" rules="required">
                 <b-input-group>
                   <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'"  @click="openToSelectFile" >
                      <b-form-file type="file" v-model="new_document.file" id="fileautreProsdocumentFichePersonne"  accept=".jpg, .png, .pdf, .doc, .docx"  placeholder="Choisissez un fichier ou déposez-le ici..."  drop-placeholder="Déposez le fichier ici..." :state="errors.length > 0 ? false : null" class="d-none"  />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>

                  </b-input-group-prepend>
                <b-form-input disabled placeholder="Choisissez un document" :value="new_document.file ? new_document.file.nom : ''"  :state="errors.length > 0 ? false : null" />

                </b-input-group>
              </validation-provider>-->

            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautredocumentfichepersonne" class="d-none" v-model="new_document.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="new_document.file ? new_document.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- </b-form-group> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <!-- <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">

      </div>
    </div> -->
    <b-card :title="isFromMenu ? '' : ''" :body-class="isFromMenu ? '' : 'py-0'">
       <div class="d-flex justify-content-end mb-1">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class="ml-1" v-b-toggle.add_document_sidebar @click="docNatureFill(true)">
          <feather-icon icon="PlusIcon" />
          <span class="align-middle text-uppercase"> Ajouter un Document</span></b-button
        >
       </div>
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Type de document" label-for="filtre-type-document">
              <b-form-select id="filtre-type-document" v-model="typeSelected">
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
                <b-form-select-option v-for="type in typesDocument" :key="type.id"
                                      :value="type.type"
                >{{ type.type }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="N° contrat / N° devis" label-for="filtre-contrat-document">
              <b-form-input id="filtre-contrat-document" v-model="contratSearch" placeholder="N° contrat / N° devis"/>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="btn-icon mt-2"
                v-b-tooltip.hover.top="'Tout afficher'"
                variant="primary"
                @click="resetFilter">
                <feather-icon icon="RefreshCwIcon"/></b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <!-- table -->
      <vue-good-table
        @on-selected-rows-change="selectionChanged"
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'document.date_creation_document', type: 'desc' }
        }"
        theme="my-theme"
      >
        <div class="text-center align-middle" slot="emptystate">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'document.document_type.document_nature.libelle'">
            {{ $_.get(props.row.document, 'document_type.document_nature.libelle', null) }}
          </span>

          <span v-if="props.column.field === 'contrat.contrat_no'"> 
            {{ $_.get(props.row.etude, 'source', null) == 'Interne' ? $_.get(props.row.contrat, 'contrat_no', null) : $_.get(props.row.contrat, 'police_no', null) }}
            {{ ($_.get(props.row.contrat, 'contrat_no', null) || $_.get(props.row.contrat, 'police_no', null)) && ($_.get(props.row.devis, 'devis_no_interne', null) || $_.get(props.row.devis, 'devis_no_externe', null)) ? ' / ' : '' }}
            {{ $_.get(props.row.etude, 'source', null) == 'Interne' ? $_.get(props.row.devis, 'devis_no_interne', null) : $_.get(props.row.devis, 'devis_no_externe', null) }}
          </span>

          <span v-if="props.column.field === 'document.document_type.type'">
            {{ $_.get(props.row.document, 'document_type.type', null) }}
          </span>

          <span v-if="props.column.field === 'document.nom_custom'">
            {{ $_.get(props.row.document, 'nom_custom', null) }}
          </span>

          <span v-if="props.column.field === 'document.date_creation_document'">
            {{ changeFormatDate($_.get(props.row.document, 'date_creation_document', null)) }}
          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'actions'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item @click="displayDocument(props.row.document.id, props.row.document.nom, props.row.document.nom_custom)">
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span>Visualiser</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDocumentClient(props.row.document.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
          
          <b-row class="mt-2 align-items-center">
            <b-col md="4">
              <b-input-group>
                  <b-form-select
                      v-model="selected_action"
                      :options="options"
                  />
                  <b-input-group-append>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="action_masse(selected_action)"
                    >
                      <span>Valider </span>
                      <feather-icon
                          class="mr-50"
                          icon="ArrowRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <!-- <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} entrées </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template> -->
      </vue-good-table>
    </b-card>
    <b-modal v-if="can('Visualize document compliance')" id="modal-display-document" ref="modal-display-document"
             :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" cancel-title="Fermer"
             cancel-variant="outline-secondary"
             ok-title="Télécharger"
             @ok="downloadDocument(document.base64, document.nameToDownload)"
    >
      <form ref="form" :style="{height : document.extensionDocument === 'PDF' ? '80vh' : 'auto', 'overflow': 'hidden'}">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid/>
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()"/>
      </form>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { BModal, VBTooltip, BImg, BAvatar, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BCard, BDropdown, BDropdownItem, BSidebar, VBToggle, BFormTextarea, BFormFile,BInputGroup,BInputGroupPrepend,BInputGroupAppend, } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    Ripple,
    BCardCode,
    VueGoodTable,
    ToastificationContentVue,
    BAvatar,
    BPagination,
    BForm,
    BModal,
    BImg,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSidebar,
    VBToggle,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
     VBTooltip
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip':VBTooltip,
    Ripple
  },
  props: ['prospectDevis'],
  data() {
    return {
      moment,
      isFromMenu: true,
      contratSearch: null,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      currentUser: JSON.parse(localStorage.getItem('userData')),
      sideBarIsActive: false,
      projets: [],
      pageLength: 5,
      dir: false,
      options: [
        { value: null, text: 'Choisissez' },
        // { value: 'Modifier', text: 'Modifier' },
        // { value: 'Archiver', text: 'Archiver' },
        { value: 'Supprimer', text: 'Supprimer' }
      ],
      selected_action: null,
      columns: [
        {
          label: 'N° contrat / N° devis',
          field: 'contrat.contrat_no',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Type de document',
          field: 'document.document_type.type',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        // {
        //   label: 'Nom de document',
        //   field: 'document.nom_custom',
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6'
        // },
        // {
        //   label: 'Description',
        //   field: 'document.commentaire'
        // },
        {
          label: "Date de l'ajout",
          field: 'document.date_creation_document',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rows: [],
      clients: null,
      searchTerm: '',
      prospectDevisLocal: [],
      new_document: {
        nom: null,
        famille: null,
        type: null,
        description: null,
        file: null
      },
      famille_documents: [],
      typesDocument: [],
      type_documents: [],
      selectedrows_masse : [],
      typeSelected: null,
      rowsDocuments: [],
      rowsDocumentsKeep: [],
    }
  },
  watch: {
    typeSelected() {
      this.filterData()
    },
    contratSearch() {
      this.filterData()
    },
    prospectDevis: {
      deep: true,
      handler(val) {
        this.prospectDevisLocal = val
        this.rows = this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient
        this.rowsDocumentsKeep = this.rows
      }
    }
  },
  created() {
    this.docNatureFill(false)
  },
  methods: {
    resetFilter() {
      this.typeSelected = null
      this.contratSearch = null
    },
    filterData() {
      this.rows = this.rowsDocumentsKeep
      if (this.typeSelected) this.rows = this.rowsDocumentsKeep.filter(item => item.document.document_type.type === this.typeSelected)
      if (this.contratSearch) this.rows = this.rows.filter(item => 
      item.contrat !== null && (item.contrat.contrat_no?.toLowerCase().includes(this.contratSearch?.toLowerCase()) || item.contrat.police_no?.toLowerCase().includes(this.contratSearch?.toLowerCase())) 
      || item.devis !== null && (item.devis.devis_no_externe?.toLowerCase().includes(this.contratSearch?.toLowerCase()) || item.devis.devis_no_interne?.toLowerCase().includes(this.contratSearch?.toLowerCase())))
    },
   openToSelectFile: () => {
      document.getElementById('fileautredocumentfichepersonne').click()
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sûr de vouloir supprimer les documents ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("document/documentDeleteMultiple/",{'documents' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for (let i = 0; i < this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient.length; i++) {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if (this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient[i].document.id == res.data.deleted[j].id) {
                            this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient.splice(i, 1)
                          }
                        }
                      }
                      this.messageToast(res.data.success, 'Succès', 'success')
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
          case 'Archiver':

          break;
        default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params;
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    changeFormatDate(date) {
      return date ? moment(date).lang('fr').format('L') : null
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (res.data.data.isDownload) {
              this.downloadDocument(res.data.data.base64, name)
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              // const pdfWindow = window.open('')
              // pdfWindow.document.write(`<iframe width='100%' height='100%' src='${res.data.data.base64}' />`)
              // this.downloadDocument(res.data.data.base64, name)
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    docNatureFill(sideBarIsActive) {
      this.sideBarIsActive = sideBarIsActive
      this.$http.get('document_nature/listdocument').then(r => {
        this.famille_documents = r.data
        if(!sideBarIsActive) {
          r.data.forEach(el => {
              el.documentype.forEach(DocumentType => {
                this.typesDocument.push(DocumentType)
              })
          })
        }
      })
    },
    docTypeFill() {
      this.type_documents = null
      for (let i = 0; i < this.famille_documents.length; i++) {
        if (this.famille_documents[i].id == this.new_document.famille) {
          this.type_documents = this.famille_documents[i].documentype
        }
      }
    },
    AddDocument() {
      let config = { headers: { 'Content-Type': 'multipart/form-data' } }
      let formData = new FormData()
      this.$refs.addDocumentValidations.validate().then(success => {
        if (success) {
          formData.append('user_id', this.currentUser.id)
          formData.append('courtier_id', this.currentUser.courtier_user[0].courtier_id)

          formData.append('type_doc_id', this.new_document.type)
          formData.append('type_doc_nom', this.new_document.nom)
          formData.append('file', this.new_document.file)
          formData.append('note', this.new_document.description)
          formData.append('type_tiers', this.prospectDevisLocal.enum.getprospectpersonephysique.type)
          formData.append('tiers', this.prospectDevisLocal.enum.getprospectpersonephysique.id)
          this.$http
            .post('document/createNewDocumentTier', formData, config)
            .then(r => {
              if (!r.data.data.max_size) {
                this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient.unshift(r.data.data.data)

                this.messageToast(`Le Document ${this.new_document.nom} a été ajouté`, 'Succès', 'success')
                this.$refs.addDocumentForm.reset()
                this.sideBarIsActive = false
              } else {
                const error = {
                  document: r.data.message
                }
                this.$refs.addDocumentValidations.setErrors(error)
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
        // else {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: `Veuillez remplir les champs obligatoires`,
        //       icon: 'UserIcon',
        //       variant: 'danger'
        //     }
        //   })
        // }
      })
    },
    deleteDocumentClient(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce document ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.$http
            .post('document/deleteDocumentClient', { document_id: id })
            .then(res => {
              for (let i = 0; i < this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient.length; i++) {
                if (this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient[i].document.id == id) {
                  this.prospectDevisLocal.enum.getprospectpersonephysique.documentclient.splice(i, 1)
                  break
                }
              }
              this.messageToast(res.data.message, 'Succès', 'success')
            })
            .catch(error => {
              console.log(error)
              this.messageToast(error.response.data, 'Erreur', 'error')
            })
        }
      })
    }
  }
}
</script>
